import React from 'react'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { Popover } from 'antd'
import images from 'Themes/Images'

import Menu from '../Menu'
import './styles.scss'

const NavbarMobile = ({
  currentUser,
  handleLogOut,
  isOnAdmin,
  isSuperAdmin,
  history,
  isMenuUserVisible,
  userRef,
  setIsMenuUserVisible,
  isAdmin,
  handleAdmin,
  preview
}) => {
  return (
    <div className="header__mobile">
      <div className="navbar__mobile">
        <Menu
          isOnAdmin={isOnAdmin}
          isSuperAdmin={isSuperAdmin}
          preview={preview}
        />
        {currentUser && (
          <div className="navbar__account" style={{ fontSize: 12 }}>
            <Popover
              placement="bottomRight"
              overlayClassName="popup_confirm popup_confirm-2"
              content={
                <div className="popup-cta">
                  {isOnAdmin ? (
                    <div
                      className="popup-cta__wrap"
                      onClick={() => {
                        history.push('/admin/settings?route=branding')
                      }}
                    >
                      <div className="popup-cta__text">Settings</div>
                    </div>
                  ) : null}
                  {isAdmin && (
                    <div className="popup-cta__wrap" onClick={handleAdmin}>
                      <div className="popup-cta__text">Admin</div>
                    </div>
                  )}
                  <div className="popup-cta__wrap" onClick={handleLogOut}>
                    <div className="popup-cta__text">Logout</div>
                  </div>
                </div>
              }
              trigger="click"
              visible={isMenuUserVisible}
            >
              <div
                ref={userRef}
                className="navbar__account__fullname"
                onClick={() => setIsMenuUserVisible(!isMenuUserVisible)}
              >
                {get(currentUser, 'name', '') ||
                  get(currentUser, 'partner.name', '')}
                <div style={{ marginLeft: 5 }}>
                  <img src={images.header.caret_down_nav} alt="" />
                </div>
              </div>
            </Popover>
          </div>
        )}
      </div>

      {!currentUser && (
        <div className="auth-wrap">
          <div className="auth__login">
            <Link to="/auth?login=true&student=true">LOG IN</Link>
          </div>
          <div className="auth__signup">
            <Link to="/auth?signup=true">
              <button className="quaternary-btn">REGISTER</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavbarMobile
