import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import Dropdown from 'react-dropdown'

import images from 'Themes/Images'
import { useClickOutside } from 'utils/hooks'
import { addOutcomeCourse, createLearningOutcome } from '../../../../actions'

export const INITIAL_TYPE_OUTCOME = [
  { value: 'Domain', label: 'Domain' },
  { value: 'Case Study', label: 'Case Study' },
  { value: 'Roleplay', label: 'Roleplay' },
  { value: 'Written Assessment', label: 'Written Assessment' },
  { value: 'Project Presentation', label: 'Project Presentation' },
  { value: 'Project Assessment', label: 'Project Assessment' },
  { value: 'Oral Assessment', label: 'Oral Assessment' }
]

const EditType = ({
  dispatch,
  setSelectedRow,
  matchRow,
  record,
  id,
  isCustom,
  selectedSchedule
}) => {
  const [type, setType] = useState('')
  const [edit, setEdit] = useState(false)
  const learning_outcome = get(
    record,
    'assessment_criteria.learning_outcome',
    {}
  )

  useEffect(() => {
    if (!isCustom) {
      setType(learning_outcome.type)
    } else {
      setType(get(record, 'type', ''))
    }
  }, [record])

  const handleChangeOutcome = async () => {
    setEdit(false)
    setSelectedRow(null)

    if (!isCustom) {
      const needToLoadSlides = true
      dispatch(
        createLearningOutcome(
          { ...learning_outcome, type: type },
          needToLoadSlides,
          selectedSchedule,
          id
        )
      )
    } else {
      dispatch(addOutcomeCourse({ ...record, type }))
    }
  }
  const { ref } = useClickOutside(handleChangeOutcome)

  const onSelect = (data) => {
    setType(data.value)
  }
  return (
    <div className="outcome-table__column" style={{ whiteSpace: 'pre-wrap' }}>
      {edit && matchRow ? (
        <div ref={ref} style={{ width: '100%' }}>
          <Dropdown
            options={INITIAL_TYPE_OUTCOME}
            onChange={onSelect}
            value={type}
            placeholder="All Types"
            className="dropdownScheduleModal"
          />
        </div>
      ) : (
        <div>{type}</div>
      )}

      {(!matchRow || !edit) && (
        <div className="outcome-table__edit">
          <img
            src={images.admin.pen_alt_active}
            alt=""
            onClick={() => setEdit(true)}
          />
        </div>
      )}
    </div>
  )
}

export default EditType
