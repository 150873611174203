import React from 'react'

const SwitchPagePdf = ({
  pageNumber,
  numPages,
  handleNextPage,
  handlePreviousPage
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14
      }}
    >
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <div style={{ zIndex: 1000, position: 'relative' }}>
        <span onClick={handlePreviousPage} style={{ cursor: 'pointer' }}>
          Previous
        </span>{' '}
        |{' '}
        <span style={{ cursor: 'pointer' }} onClick={handleNextPage}>
          Next
        </span>
      </div>
    </div>
  )
}

export default SwitchPagePdf
