import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { get, isEmpty } from 'lodash'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { Row, Col, Modal, AutoComplete, message } from 'antd'
import { uploadImage } from 'src/container/CMS/hooks.js'
import images from 'Themes/Images'
import Input from 'component/Input'
import BtnPrimary from 'component/BtnPrimary'
import ClientCourse from 'src/customs/SuperAdmin/components/ClientCourse'
import {
  hideFormClientModal,
  hideGlobalLoading,
  showGlobalLoading,
} from '../../../actions'
import { mapListCourseToSearch } from 'utils/helper'
import { loadListCourse } from 'container/Home/actions'
import {
  makeSelectListCourse,
  makeSelectListDomain,
} from 'container/Home/selectors'
import config from 'src/global-config'
import { ERROR_MESSAGE } from 'utils/constants'
import { getResponseData } from 'utils/helper'
import { makeSelectDomain } from 'container/Home/selectors'
import axiosInstance from 'utils/axiosInstance'
import { showError, showSuccess } from 'utils/notification'

const UserSchema = Yup.object().shape({
  create_name: Yup.string().required(ERROR_MESSAGE),
})

const FormClientModal = ({ showFormClientModal, dispatch }) => {
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedClient, setSelectClient] = useState(null)
  const [textSearch, setTextSearch] = useState('')
  const listCourses = useSelector(makeSelectListCourse()) || []
  const listDomain = useSelector(makeSelectListDomain())
  const [logo, setLogo] = useState(null)
  const [banner, setBanner] = useState(null)
  const domain = useSelector(makeSelectDomain())
  const website_id = get(domain, 'id')

  const client = get(showFormClientModal, 'client', null)
  const nextCoursePublished = listCourses?.filter((item) => item?.is_published)

  const listCourseOfClient = useMemo(
    () => get(selectedClient, 'channel_clients', []),
    [selectedClient]
  )

  const listStudentOfClient = useMemo(
    () => get(selectedClient, 'user_clients', []),
    [selectedClient]
  )

  const logoFileRef = useRef(null)
  const photoFileRef = useRef(null)

  useEffect(() => {
    if (Array.isArray(listCourses) && listCourses.length === 0) {
      dispatch(loadListCourse({ superAdmin: true }))
    }
  }, [listCourses])

  const reloadClient = async () => {
    try {
      const res = await axiosInstance.get(
        `api/clients?name=${selectedClient?.name}`
      )
      const data = getResponseData(res)
      setSelectClient(get(data, '[0]', null))
    } catch (error) {
      console.log('~ error', error)
    }
  }

  const formatSlug = (name) => {
    return name.trim().toLowerCase().replace(/ /g, '-')
  }
  useEffect(() => {
    if (client) {
      formik.setFieldValue('create_name', get(client, 'name'))
      formik.setFieldValue(
        'slug',
        get(client, 'slug')
          ? formatSlug(get(client, 'slug'))
          : formatSlug(get(client, 'name'))
      )
      const logo = get(client, 'logo', '')
      const banner = get(client, 'banner', '')
      setLogo({ image_url: logo })
      setBanner({ image_url: banner })
      setSelectClient(client)
    } else {
      handleResetField()
    }
  }, [client])

  const formik = useFormik({
    initialValues: {
      create_name: '',
      sales: null,
      admin: null,
      password: '',
      company: '',
    },
    validationSchema: UserSchema,
    onSubmit: async (values) => {
      dispatch(showGlobalLoading())

      const params = {
        name: values.create_name,
        logo: logo?.image_url,
        banner: banner?.image_url,
        slug: values?.slug,
        website_id,
      }

      if (!client) {
        await putClient(params)
      } else {
        await putClient({ ...params, id: client?.id }, client?.id)
      }
      try {
        await showFormClientModal.refetch()
      } catch (e) {
        // Handle cancellation
      }
      handleResetField()
      dispatch(hideFormClientModal())
      dispatch(hideGlobalLoading())
    },
  })

  const putClient = async (params, id) => {
    try {
      const res = await axiosInstance.put(
        `${config.baseUrl}/api/clients`,
        params
      )
      const data = getResponseData(res)
      if (data?.id) {
        if (id) {
          showSuccess('Update client succeed!')
        } else {
          showSuccess('Create client succeed!')
        }
      }
    } catch (error) {
      showSuccess('Create client failed!')
      console.log('error', error)
    }
    return
  }

  const handleRemoveLogo = (isBanner) => {
    setLogo(null)
  }

  const handleRemoveBanner = () => {
    setBanner(null)
  }

  const handleResetField = () => {
    formik.resetForm({})
  }

  const handleCancel = () => {
    if (client) {
      handleResetField()
    }
    dispatch(hideFormClientModal())
  }

  const renderLogo = () => {
    if (logo && logo.image_url) {
      return logo.image_url + '=s0'
    }

    return images.admin.logo
  }
  const renderBanner = () => {
    if (banner && banner.image_url) {
      return banner.image_url + '=s0'
    }

    return images.admin.course_img_xample
  }

  const onSelectCourse = (value, option) => {
    setSelectedCourse(option.data)
  }

  const handleClickLogo = () => {
    logoFileRef.current.click()
  }

  const handleClickBanner = () => {
    photoFileRef.current.click()
  }

  const addCourseToClient = async (courseID) => {
    try {
      await axiosInstance.put('/api/clients/channels', {
        client_id: client?.id,
        channel_id: courseID,
      })
      message.success('Add course to client success!')
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleAddCourse = async () => {
    if (!selectedCourse) {
      return
    }
    await addCourseToClient(get(selectedCourse, 'id'))
    await reloadClient()
    showFormClientModal?.refetch && showFormClientModal.refetch()
    setTextSearch('')

    await assigneStudentsCourse()
  }

  const postEnroll = async (channelID, userID, partnerID) => {
    try {
      const res = await axiosInstance.post(
        '/api/lms/courses/channels/partners',
        {
          channel_id: channelID,
          partner_id: partnerID,
          user_id: userID,
          schedule_id: 0,
          is_send: true,
        }
      )

      const data = getResponseData(res)
      return data
    } catch (err) {
      console.log('err', err)
    }
  }

  const getUserById = async (userID) => {
    try {
      const res = await axiosInstance.get(
        `/api/users?user_id=${userID}&ignore_company=true`
      )
      const data = getResponseData(res)

      return get(data, '[0]', null)
    } catch (error) {
      return null
    }
  }

  const loadUserInfo = async () => {
    const data = await Promise.all(
      listStudentOfClient.map((item) => getUserById(item?.user_id))
    )
    return data
  }

  const assigneStudentsCourse = async (course) => {
    const request = []

    if (listStudentOfClient?.length === 0) {
      return showError(`None of the clients' assigned users!`)
    }

    const userInfo = await loadUserInfo()

    for (let i = 0; i < listStudentOfClient.length; i++) {
      const channelID = !isEmpty(course)
        ? get(course, 'id')
        : get(selectedCourse, 'id')
      const userID = listStudentOfClient[i].id
      const partnerID = userInfo[i].partner_id
      request.push(postEnroll(channelID, userID, partnerID))
    }

    const data = await Promise.all(request)
    const lengthEnrolled = data.filter((x) => !!x).length
    showSuccess(
      lengthEnrolled > 0
        ? `${lengthEnrolled} users enrolled to this course`
        : 'All the users enrolled to this course'
    )
  }

  const handleChangeLogo = async (event) => {
    const fileUploaded = event.target.files[0]

    if (fileUploaded) {
      dispatch(showGlobalLoading())
      const imageUrl = await uploadImage(fileUploaded)
      if (imageUrl) {
        setLogo({
          name: imageUrl.name,
          image_url: imageUrl.url,
          size: fileUploaded.size,
        })
      }
      dispatch(hideGlobalLoading())
    }
  }

  const handleChangeBanner = async (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      dispatch(showGlobalLoading())
      const imageUrl = await uploadImage(fileUploaded)
      if (imageUrl) {
        setBanner({
          name: imageUrl.name,
          image_url: imageUrl.url,
          size: fileUploaded.size,
        })
      }
      dispatch(hideGlobalLoading())
    }
  }

  return (
    <Modal
      visible={showFormClientModal?.open}
      footer={null}
      onCancel={handleCancel}
      className="sc-modal sc-modal-user"
    >
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div
          className="sc-modal__header"
          style={{ backgroundColor: '#f9f9fb' }}
        >
          <div className="sc-modal__heading">
            {client ? `${client?.name}` : 'Add new client'}
          </div>
        </div>

        <div className="sc-modal__main">
          <Row>
            <Col xs={24} sm={10}>
              <Input
                noScroll
                label="NAME*"
                value={formik.values.create_name}
                onChange={(e) => {
                  formik.setFieldValue('create_name', e.target.value)
                  if (isEmpty(selectedClient)) {
                    formik.setFieldValue('slug', formatSlug(e.target.value))
                  }
                }}
                name="create_name"
                autoComplete="off"
                placeHolder="Enter client name"
                formik={formik}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={10}>
              <Input
                noScroll
                label="Page url"
                value={formik.values.slug}
                onChange={formik.handleChange}
                name="slug"
                autoComplete="off"
                placeHolder="Enter client page url"
                formik={formik}
              />
            </Col>
          </Row>
          <Row>
            <div className="category__setup__center">
              <div>
                <div className="field__label">Client Logo</div>
                <div className="inner_container">
                  <div
                    className="category__setup__upload-wrap"
                    style={{ padding: 0, alignItems: 'center' }}
                  >
                    <div
                      className="add-course__img"
                      style={{
                        marginRight: 30,
                        width: 240,
                        height: 140,
                        borderRadius: 20,
                      }}
                    >
                      <img src={renderLogo()} alt="" />
                    </div>
                    <div className="category__setup__cta">
                      <div className="add-course__file-upload">
                        <button
                          className="tertiary-btn"
                          type="button"
                          onClick={handleClickLogo}
                          style={{ width: '111px!important' }}
                        >
                          <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={logoFileRef}
                            onChange={handleChangeLogo}
                          />
                          <img src={images.header.image_none_bg} alt="" />
                          <span>Browse</span>
                        </button>
                      </div>
                      <div
                        className="add-course__file-remove"
                        onClick={() => handleRemoveLogo(false)}
                      >
                        <button className="tertiary-btn" type="button">
                          <img src={images.admin.trash_icon} alt="" />{' '}
                          <span>Discard</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className="inner_container">
              <div className="field__label">Client Banner</div>
              <div
                className="add-course__upload-wrap-first"
                style={{ padding: 0 }}
              >
                <div className="add-course__img" style={{ marginRight: 30 }}>
                  <img src={renderBanner()} alt="" />
                </div>
                <div className="add-course__file-type">
                  <div className="add-course__file-upload">
                    <button
                      className="tertiary-btn"
                      type="button"
                      onClick={handleClickBanner}
                      style={{ width: '111px!important' }}
                    >
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={photoFileRef}
                        onChange={handleChangeBanner}
                      />
                      <img src={images.header.image_none_bg} alt="" />
                      <span>{!banner ? 'Upload' : 'Reupload'}</span>
                    </button>
                  </div>
                  <div
                    className="add-course__file-remove"
                    onClick={handleRemoveBanner}
                  >
                    <button className="tertiary-btn" type="button">
                      <img src={images.admin.trash_icon} alt="" />{' '}
                      <span>Discard</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          {client && (
            <>
              <h2 className="sc-modal__heading" style={{ marginTop: 20 }}>
                Course Assigned To Client
              </h2>
              <Row>
                <Col xs={16} sm={24}>
                  {Array.isArray(nextCoursePublished) &&
                    nextCoursePublished.length !== 0 &&
                    listCourseOfClient && (
                      <AutoComplete
                        className="search_course"
                        options={mapListCourseToSearch(
                          nextCoursePublished,
                          listCourseOfClient
                        )}
                        placeholder="Search a course name"
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        value={textSearch}
                        onChange={(value) => setTextSearch(value)}
                        onSelect={onSelectCourse}
                      />
                    )}
                </Col>
              </Row>
              <Row>
                <Col xs={16} sm={20} className="mgT-10">
                  <BtnPrimary
                    name="Add course"
                    style={{
                      padding: '10px 14px',
                      transition: 'all 0.2s',
                      backgroundColor: '#E7EBF4',
                      fontWeight: 'bold',
                      color: '#6F84A0',
                      marginBottom: 10,
                    }}
                    iconLeft={images.admin.plus}
                    type="button"
                    handleClick={handleAddCourse}
                  />
                </Col>
              </Row>
              {listCourseOfClient && listCourses && (
                <ClientCourse
                  listDomain={listDomain}
                  dispatch={dispatch}
                  user={client}
                  listCourseOfClient={listCourseOfClient}
                  listCourses={listCourses}
                  reloadClient={reloadClient}
                  refetch={showFormClientModal?.refetch}
                  setSelectedCourse={setSelectedCourse}
                  assigneStudentsCourse={assigneStudentsCourse}
                />
              )}
            </>
          )}
        </div>

        <div
          className="sc-modal__footer"
          style={{
            justifyContent: 'flex-end',
            backgroundColor: '#f9f9fb',
            paddingRight: 53,
          }}
        >
          <BtnPrimary
            name="Cancel"
            style={{
              padding: 12,
              transition: 'all 0.2s',
              marginRight: 20,
              backgroundColor: '#E7EBF4',
              fontWeight: 'bold',
              color: '#6F84A0',
            }}
            iconLeft={images.admin.x_inactive}
            type="button"
            handleClick={handleCancel}
          />
          <BtnPrimary
            name="Save changes"
            style={{
              padding: '12px 40px',
              fontWeight: 'bold',
              transition: 'all 0.2s',
            }}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  )
}

export default FormClientModal
