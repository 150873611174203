import config from '../global-config'
import * as navigation from 'utils/navigation'
import { get } from 'lodash'
import { getProfile } from 'utils/profileUtils'
import { stringify } from 'query-string'
import axiosInstance from 'utils/axiosInstance'
import { getResponseData } from 'utils/helper'
import dayjs from 'dayjs'
const url = window.location.href
const arr = url.split('/')
const result = arr[0] + '//' + arr[2]

let accessToken = ''

export function setAccessToken(token) {
  accessToken = token
}

export function getAccessToken() {
  return accessToken
}
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else if (response.status === 401) {
    accessToken = ''
    navigation.navigate('/auth?login=true&student=true')
  }
  return response
}

export default function request(url, options) {
  const parsedOptions = Object.assign(
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        host: result,
      },
    },
    options
  )

  return fetch(url, parsedOptions).then(checkStatus).then(parseJSON)
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.json()
}

export const getUserInfo = async (userId, website_id) => {
  try {
    const request = `${config.baseUrl}/api/users/${userId}/profiles`
    const res = await fetch(request, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    const data = await res.json()
    if (Array.isArray(data.data)) {
      return getProfile(website_id, data.data)
    }
  } catch (error) {
    console.log('error', error)
  }
}

export const addClientInfoForUserSignUp = async (body) => {
  try {
    const request = `${config.baseUrl}/api/clients/users`
    const res = await fetch(request, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })
    const data = await res.json()
    return data.data
  } catch (error) {
    console.log('error', error)
  }
}

export const createUserInfo = async (userId, body) => {
  try {
    const request = `${config.baseUrl}/api/users/${userId}/profiles`
    const res = await fetch(request, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })
    const data = await res.json()
    return data.data
  } catch (error) {
    console.log('error', error)
  }
}

export const getUserPaymentMethod = async (userId) => {
  try {
    const res = await fetch(
      `${config.baseUrl}/api/users/${userId}/payment-method`,
      { method: 'GET' }
    )

    const data = await res.json()
    return data.data
  } catch (error) {
    console.log('err', error)
  }
}

export const getInvoiceFLCDomain = async (
  invoiceNo,
  studentName,
  selectedSchedule,
  price,
  companyName,
  course,
  dateEnrolled,
  billingAddress,
  companyBilling
) => {
  try {
    const res = await fetch(`${config.api}/send-mail/invoice-flc`, {
      body: JSON.stringify({
        trainee_name: studentName,
        invoice_no: invoiceNo,
        student_name: studentName,
        course_start_date: get(selectedSchedule, 'start_date'),
        course_end_date: get(selectedSchedule, 'end_date'),
        course_fee: parseFloat(price),
        course: course,
        company_sponsored: companyName,
        company_billing_address: companyBilling,
        date_enrollment: dateEnrolled,
        billing_address: billingAddress,
      }),
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
    })
    const data = await res.json()
    if (data.pdf) {
      return data
    }
  } catch (error) {
    console.log('error', error)
  }
}

export const getInvoiceUnicornDomain = async (
  invoiceNo,
  studentName,
  selectedSchedule,
  course,
  courseFee
) => {
  try {
    const res = await fetch(`${config.api}/pdf`, {
      body: JSON.stringify({
        trainee_name: studentName,
        invoice_no: invoiceNo,
        student_name: studentName,
        course_start_date: dayjs(get(selectedSchedule, 'start_date')).format(
          'YYYY-MM-DD'
        ),
        course_end_date: dayjs(get(selectedSchedule, 'end_date')).format(
          'YYYY-MM-DD'
        ),
        course_fee: courseFee,
        course: course,
      }),
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
    })
    const data = await res.json()
    if (data.pdf) {
      return data
    }
  } catch (error) {
    console.log('error', error)
  }
}

export const updatePaymentMethod = async (body) => {
  try {
    const res = await fetch(`${config.baseUrl}/api/payments/payment-method`, {
      method: 'PUT',
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })

    const data = await res.json()

    return data?.data
  } catch (err) {
    return false
  }
}

export const getPaymentStatus = async (data) => {
  const channel_id = get(data, 'channel_id')
  const nric = get(data, 'nric')
  const claim_id = get(data, 'value')
  const requestURL = `${config.baseUrl}/api/payments/sfc/claims?channel_id=${channel_id}&claim_id=${claim_id}&nric=${nric}`
  try {
    const res = await fetch(requestURL, {
      method: 'GET',
    })
    const response = await res.json()
    const data = get(response, 'data.data')

    return data
  } catch (err) {
    console.log('err', err)
  }
}

const defaultFilter = {
  page: 1,
  per_page: 15,
  order_by: 'id desc',
}

export const getClients = async (baseFilter) => {
  try {
    const params = stringify(
      baseFilter ? { ...baseFilter } : { ...defaultFilter }
    )
    const res = await axiosInstance.get(`/api/clients?${params}`)
    const data = getResponseData(res)
    return data
  } catch (error) {
    console.log('error', error)
    return null
  }
}
