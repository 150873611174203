import React, { useMemo, useEffect, useState } from 'react'
import { Banner, Body, Layout } from '../../../styles'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'

import { get, isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { makeSelectListCourse } from 'container/Home/selectors'
import { mapListCourseOfSeller } from 'utils/helper'
import { loadListCourse } from 'container/Home/actions'
import ListCourses from 'component/ListCourses'
import axiosInstance from 'utils/axiosInstance'
import { getResponseData } from 'utils/helper'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { showError } from 'utils/notification'
import useFirstRender from 'src/hooks/useFirstRender'
import { isEnrollCourse } from 'utils/helper'
import { setClient } from 'container/Checkout/actions'
import { useMediaQuery } from 'react-responsive'

const MainDescription = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 100px;

  @media (max-width: 767px) {
    display: block;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 100%;
    color: #ffffff;
    text-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
    @media (max-width: 767px) {
      font-size: 27px;
    }
  }
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 145.6%;
    font-size: 18px;
    color: #ffffff;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
  }
  .logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #fff;
    padding: 10px;
    margin-right: 30px;
    img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 767px) {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }
  }
  section {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      display: block;
    }
  }
`

const ClientCoursesListing = ({}) => {
  const dispatch = useDispatch()
  const isFirstRender = useFirstRender()
  const history = useHistory()
  const { client } = useParams()
  const [clientData, setClientData] = useState(null)
  const currentUser = useSelector(makeSelectCurrentUser())
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const userClients = useMemo(
    () => get(clientData, 'user_clients', []),
    [clientData]
  )
  const courseClients = useMemo(
    () => get(clientData, 'channel_clients', []),
    [clientData]
  )

  const preventUserAccessRoute = () => {
    const noneExistUser = userClients.find(
      (item) => item?.user_id === currentUser?.uid
    )

    if (!!currentUser?.is_admin) {
      return
    }

    if (!noneExistUser) {
      showError('You are not allowed to access this page!')
      return history.push('/')
    }
  }

  useEffect(() => {
    if (!isFirstRender && !isEmpty(userClients)) {
      if (!!currentUser) {
        preventUserAccessRoute()
      } else {
        return history.push('/auth?login=true&student=true')
      }
    }
  }, [userClients, currentUser, isFirstRender])

  const getClientBySlug = async (name) => {
    try {
      const res = await axiosInstance.get(
        `/api/clients?slug=${name.toLowerCase()}`
      )

      const data = getResponseData(res)
      data?.length > 0 && localStorage.setItem('clientID', data[0]?.id)

      if (data && data.length > 0) {
        return data[0]?.slug === client?.toLowerCase() ? data[0] : null
      }
      return null
    } catch (error) {
      console.log('err', error)
      return null
    }
  }

  useEffect(() => {
    const getClient = async () => {
      try {
        const res = await getClientBySlug(client)
        if (res) {
          dispatch(setClient(res))
          setClientData(res)
        } else {
          history.push('/pagenotfound')
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    if (client) {
      getClient()
      dispatch(loadListCourse())
    }
  }, [client])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])

  const listCourses = useSelector(makeSelectListCourse())

  const courses = useMemo(() => {
    return mapListCourseOfSeller(courseClients, listCourses)
  }, [listCourses, courseClients])

  const coursesFiltered = useMemo(() => {
    if (courses) {
      if (
        get(currentUser, 'is_admin', false) ||
        get(currentUser, 'is_trainer', false)
      ) {
        return courses
      }
      return courses.filter((course) => {
        const isEnroll = isEnrollCourse(get(course, 'partners', []), course)

        return course?.course_visibility ? (isEnroll ? course : null) : course
      })
    }
  }, [currentUser, courses])

  return (
    <Layout>
      <Banner
        img={get(clientData, 'banner', '') + '=s0'}
        height={280}
        heightMobile={215}
        top={50}
      />
      <Body padding={isMobile ? '10px 20px 70px' : null}>
        <MainDescription>
          {clientData && (
            <section>
              <div className="logo">
                <img
                  alt={get(clientData, 'name', '')}
                  src={get(clientData, 'logo', '')}
                />
              </div>
              <h1>{get(clientData, 'name', '')}</h1>
            </section>
          )}
        </MainDescription>

        {Array.isArray(coursesFiltered) && coursesFiltered.length > 0 && (
          <ListCourses courses={coursesFiltered} activeTitle />
        )}
      </Body>
    </Layout>
  )
}

export default ClientCoursesListing
