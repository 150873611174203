import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import config from 'src/global-config'
import request from 'utils/request'
import { get, isEmpty, orderBy } from 'lodash'
import { stringify } from 'query-string'

import * as constants from './constants'
import * as actions from './actions'
import * as modalActions from 'container/Modal/actions'
import * as selectors from './selectors'
import * as navigation from 'utils/navigation'

import { setDomain } from 'utils/validate'
import { showError } from 'utils/notification'
import { mapSlidesToSideBar } from '../../utils/mapper'
import { makeSelectCurrentUser } from '../Auth/selectos'
import { showGlobalLoading, hideGlobalLoading } from '../Modal/actions'
import { userType } from 'utils/helper'
import { domainDev } from 'utils/constants'
import { loadListCourse } from 'container/Home/actions'
import { getAccessToken } from 'utils/request'
import { makeSelectCourse } from 'container/Home/selectors'
import { getProfile } from 'utils/profileUtils'
import dayjs from 'dayjs'
import { DOMAIN } from '../../routes'

function* onLoadListCourse(action) {
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  const superAdmin = get(action, 'params.superAdmin', false)
  const partner = get(action, 'params.partner', '')
  const filter = get(action, 'params.filter', '')
  const filterByTags = get(action, 'params.filterByTags', null)
  const keyword = get(action, 'params.keyword', '')
  const page = get(action, 'params.page', '')
  const perpage = get(action, 'params.perpage', '')
  const admin = get(action, 'params.admin', '')
  const catId = get(action, 'params.catId', '')
  const listIds = get(action, 'params.listIds', '')
  const loadingCourseCompare = get(action, 'params.loadingCourseCompare', false)
  const sorting = get(action, 'params.sorting', null)
  const loading = get(action, 'params.loading', false)
  const newFilter = get(action, 'params.newFilter', '')

  let requestURL = `${config.baseUrl}/api/lms/courses/channels?order_by=id%20desc&per_page=1000&ignore_per_page=true`

  if (admin) {
    if (filter === 'sequence') {
      requestURL = keyword
        ? `${config.baseUrl}/api/lms/courses/channels?order_by=id%20desc&published=all&name=${keyword}`
        : `${
            config.baseUrl
          }/api/lms/courses/channels?order_by=id%20desc&published=all${
            newFilter && '&' + newFilter
          }`
    }
    if (filter === 'total_votes') {
      requestURL = `${config.baseUrl}/api/lms/courses/channels?order_by=id%20desc`
    }
    if (filter === 'create_date') {
      requestURL = `${config.baseUrl}/api/lms/courses/channels?order_by=id%20desc&published=false`
    }
  }

  if (filterByTags) {
    requestURL = `${config.baseUrl}/api/lms/courses/channels?tag_id=${filterByTags.value}`
  }

  if (filter && keyword) {
    requestURL = `${config.baseUrl}/api/lms/courses/channels?order_by=${filter}%20desc&name=${keyword}&published=all`
  }

  if (page && perpage) {
    requestURL += `&page=${page}&per_page=${perpage}`
  }

  if (partner) {
    requestURL += `&partner_id=${partner}`
  } else if (
    !isEmpty(currentUser) &&
    get(currentUser, 'partner_id', null) &&
    !partner
  ) {
    requestURL += `&partner_id=${get(currentUser, 'partner_id')}`
  }

  if (superAdmin) {
    requestURL = `${
      config.baseUrl
    }/api/lms/courses/channels?order_by=${filter}%20asc&ignore_company=true&per_page=1000&ignore_per_page=true${
      newFilter && '&' + newFilter
    }`
  }

  if (superAdmin && partner) {
    requestURL = `${config.baseUrl}/api/lms/courses/channels?order_by=${filter}%20asc&ignore_company=true&partner_id=${partner}&per_page=1000&ignore_per_page=true`
  }

  if (superAdmin && keyword) {
    requestURL = `${config.baseUrl}/api/lms/courses/channels?order_by=${filter}%20asc&ignore_company=true&name=${keyword}`
  }

  if (!superAdmin && (catId || catId === 0)) {
    requestURL = `${config.baseUrl}/api/lms/courses/channels?order_by=${filter}%20asc&category_id=${catId}`
  }

  if (superAdmin && catId) {
    requestURL = `${config.baseUrl}/api/lms/courses/channels?order_by=${filter}%20asc&ignore_company=true&category_id=${catId}`
  }

  if (superAdmin && listIds) {
    requestURL += listIds
  }

  if (loadingCourseCompare) {
    requestURL += '&ignore_company=true'
  }

  if (!loading) {
    yield put(showGlobalLoading())
  }
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (!isEmpty(sorting)) {
      let sortedArray = []
      if (!sortedArray.includes(Object.keys(sorting)[0])) {
        sortedArray = Object.keys(sorting)
      }
      const orderArray = Object.values(sorting)
      const newCourses = orderBy(res.data, sortedArray, orderArray)

      yield put(actions.loadListCourseSuccess(newCourses))
    } else {
      yield put(actions.loadListCourseSuccess(res.data))
    }

    if (!loading) {
      yield put(hideGlobalLoading())
    }
  } catch (err) {
    if (!loading) {
      yield put(hideGlobalLoading())
    }
    console.log('err', err)
  }
}

function* onLoadCourse(action) {
  const ignoreCompany = action.ignoreCompany
  const needToLoading = action.needToLoading
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${action.params}`

  if (needToLoading) {
    yield put(modalActions.showGlobalLoading())
  }

  if (get(currentUser, 'partner_id', null)) {
    requestURL += `?partner_id=${currentUser.partner_id}`
  }

  if (ignoreCompany) {
    requestURL += `&ignore_company=true`
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadCourseSuccess(res.data))
    if (needToLoading) {
      yield put(modalActions.hideGlobalLoading())
    } else {
      yield delay(500)
      yield put(actions.preventLoadRelatedApi(false))
    }
  } catch (err) {
    if (needToLoading) {
      yield put(modalActions.hideGlobalLoading())
    }
    console.log('err', err)
  }
}

function* onLoadListCategory(action) {
  yield put(modalActions.showGlobalLoading())

  let requestURL = `${config.baseUrl}/api/lms/courses/channels/categories?order_by=id%20desc`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(actions.loadListCategorySuccess(res.data))
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())

    console.log('err', err)
  }
}

function* onLoadSlides(action) {
  const course = yield select((state) => makeSelectCourse()(state))
  const idSlide = action.idSlide
  const isAdmin = action.isAdmin
  const scheduleId = action.scheduleId
  const preview = action.preview
  const isSchedule = get(course, 'is_schedule', false)

  const selectedSlide = yield select((state) =>
    selectors.makeSelectSelectedSlide()(state)
  )
  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${action.params}/slides?order_by=sequence%20asc&order_by=id%20asc&per_page=1000&ignore_per_page=true`
  if (isAdmin) {
    requestURL += '&published=all'
  }
  if (isSchedule && scheduleId >= 0) {
    requestURL += `&schedule_id=${scheduleId}`
  }

  if (preview) {
    requestURL += '&published=all'
  }
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (res.data) {
      const lessonLength = res.data.reduce(
        (acc, curr) => acc + (!curr.is_category ? 1 : 0),
        0
      )
      const slides = mapSlidesToSideBar(res.data)

      yield put(actions.loadOriginalSlidesSuccess(res.data))
      if (isAdmin && idSlide && res.data.length !== 0) {
        const selectedSlide = res.data.find((item) => item.id === idSlide)
        yield put(actions.setSelectedSlide(selectedSlide))
        return yield put(actions.loadSlidesSuccess(slides))
      }
      if (slides.length !== 0 && slides.items !== 0 && !selectedSlide) {
        let firstPublishSlide = null

        for (let i = 0; i < slides.length; i++) {
          const item = slides[i].items.find((slide) => slide.id)
          if (!firstPublishSlide && item) {
            firstPublishSlide = item
          }
        }

        yield put(actions.setSelectedSlide(firstPublishSlide))
      }

      yield put(actions.loadSlidesSuccess(slides, lessonLength))
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onLoadSlideDetails(action) {
  const slideId = action.params.id || ''
  const requestURL = `${config.baseUrl}/api/lms/courses/slides/${slideId}`
  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadSlideDetailsSuccess(res.data))
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onLoadPreviewByChannelId(action) {
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  const requestURL = `${config.baseUrl}/api/lms/courses/slides/partners?channel_id=${action.params}&partner_id=${currentUser.partner_id}&per_page=1000&ignore_per_page=true`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(actions.loadPreviewByChannelIdSuccess(res.data))
  } catch (err) {
    console.log('err', err)
  }
}

function* onSetPreviewComplete(action) {
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  if (!currentUser) {
    return showError('You are not login yet!')
  }
  const selectedSlide = yield select((state) =>
    selectors.makeSelectSelectedSlide()(state)
  )
  const previews = yield select((state) =>
    selectors.makeSelectPreviewBySlideId()(state)
  )
  const matchLesson = previews?.find(
    (item) => item.slide_id === selectedSlide?.id
  )

  const course = yield select((state) => selectors.makeSelectCourse()(state))
  const requestURL = `${config.baseUrl}/api/lms/courses/slides/partners`
  let body = {
    channel_id: course.id,
    partner_id: currentUser.partner_id,
    slide_id: selectedSlide.id,
    user_id: currentUser.uid || currentUser.id,
    completed: matchLesson?.completed || action.params?.completed || false,
    start_date: matchLesson?.start_date || action.params?.start_date || '',
    end_date: matchLesson?.end_date || action.params?.end_date || '',
  }
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    if (get(res, 'data.id', null)) {
      yield put(actions.loadPreviewByChannelId(course.id))
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onPostUserAnswer(action) {
  const selectedQuiz = yield select((state) =>
    selectors.makeSelectSelectedQuiz()(state)
  )
  const slidesDetails = yield select((state) =>
    selectors.makeSelectSlideDetails()(state)
  )
  const questions = get(slidesDetails, 'questions', [])
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  const needToNext = action.needToNext

  const uid = get(currentUser, 'uid', '') || get(currentUser, 'id', '')
  const selectedQuizId = get(selectedQuiz, 'id', '')
  const requestURL = `${config.baseUrl}/api/users/${uid}/questions/${selectedQuizId}/answers`

  const preventLoading = action.preventLoading
  if (!preventLoading) {
    yield put(modalActions.showGlobalLoading())
  }
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify({ ...action.params, slide_id: slidesDetails.id }),
    })

    const id = get(res, 'data.id')
    const errors = get(res, 'errors')
    if (errors) {
      showError('Submit Answer Failed!')
      return yield put(modalActions.hideGlobalLoading())
    }
    if (id) {
      if (needToNext) {
        const current = questions.findIndex(
          (question) => question.id === selectedQuiz.id
        )
        if (current + 1 <= questions.length - 1) {
          yield put(actions.setSelectedQuiz(questions[current + 1]))
        }
      }

      yield put(actions.loadUserAnswer())
    }
    if (!preventLoading) {
      yield put(modalActions.hideGlobalLoading())
    }
  } catch (err) {
    if (!preventLoading) {
      yield put(modalActions.hideGlobalLoading())
    }
    console.log('err', err)
  }
}

function* onLoadUserAnswer(action) {
  const slidesDetails = yield select((state) =>
    selectors.makeSelectSlideDetails()(state)
  )
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))

  const uid = get(currentUser, 'uid', 0) || get(currentUser, 'id', '')
  const slideID = get(slidesDetails, 'id', '')
  const requestURL = `${config.baseUrl}/api/users/${uid}/slides/${slideID}/answers`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    if (res.data) {
      yield put(actions.loadUserAnswerSuccess(res.data))
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onLoadDomain(action) {
  const url = window.location.href
  const arr = url.split('/')
  const domain = arr[0] + '//' + arr[2] + '/'

  let requestURL = `${config.baseUrl}/api/websites?domain=${domain}`

  if (
    ['https://admin.hypeschools.com/', 'http://localhost:3000/'].includes(
      domain
    )
  ) {
    requestURL = `${config.baseUrl}/api/websites?per_page=1000&ignore_per_page=true`
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data)) {
      const data = res.data.find((item) => item.domain === domain)
      setDomain(data)
      yield put(actions.loadDomainSuccess(data))
      yield put(actions.loadListDomainSuccess(res.data))
    }
    if (process.env.REACT_APP_ENV === 'develop') {
      yield put(actions.loadDomainSuccess(domainDev))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onLoadUserLocation(action) {
  const requestURL = `https://ipinfo.io?token=f95b7994216dee`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    if (res && res.country) {
      yield put(actions.loadUserLocationSuccess(res))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

export const getUserById = async (ref) => {
  const requestURL = `${config.baseUrl}/api/users?user_id=${ref}&ignore_company=true`
  try {
    const res = await fetch(requestURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    const data = await res.json()

    return data
  } catch (error) {
    console.log('err', error)
    return null
  }
}

export const getCourseById = async (id) => {
  const requestURL = `${config.baseUrl}/api/lms/courses/channels/${id}`
  try {
    const res = await fetch(requestURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    const data = await res.json()

    return data?.data || null
  } catch (error) {
    console.log('err', error)
    return null
  }
}

export const getUserByEmail = async (mail) => {
  const params = stringify({ email: mail, ignore_company: true })
  const requestURL = `${config.baseUrl}/api/users?${params}`
  try {
    const res = await fetch(requestURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    const data = await res.json()
    if (data.data && data.data.length > 0) {
      return data.data[0]
    }
    return null
  } catch (error) {
    console.log('err', error)
    return null
  }
}

export const getUserByName = async (name) => {
  const requestURL = `${config.baseUrl}/api/users?name=${name}&ignore_company=true`
  try {
    const res = await fetch(requestURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    const data = await res.json()
    if (data.data && data.data.length > 0) {
      return data.data[0]
    }
    return null
  } catch (error) {
    console.log('err', error)
    return null
  }
}

const getLeadByUserId = async (id) => {
  const requestURL = `${config.baseUrl}/api/sales/interests?user_id=${id}`
  try {
    const res = await fetch(requestURL, { method: 'GET' })
    const data = await res.json()

    return data
  } catch (error) {
    console.log('err', error)
    return null
  }
}

function* onPostEnroll(action) {
  const domain = yield select((state) => selectors.makeSelectDomain()(state))
  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA
  const requestURL = `${config.baseUrl}/api/lms/courses/channels/partners`
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  const course = yield select((state) => selectors.makeSelectCourse()(state))
  const schedule = action.params.schedule
  const user_id = currentUser.uid ? currentUser.uid : currentUser.id
  const isSend = action.params.is_send || false
  const requiredSchedule = get(course, 'is_schedule', false)
  const ref = yield select((state) => selectors.makeSelectRefId()(state))
  const userAccountRef = get(currentUser, 'ref_id')

  const partners = get(course, 'partners', [])
  const enrollID = get(partners, '[0].id', null)
  if (!currentUser) {
    return showError('You are not login yet!')
  }

  if (!isEmpty(schedule)) {
    const requestUrlSchedule = `${config.baseUrl}/api/lms/courses/schedules/${schedule.id}`
    const res = yield call(fetch, requestUrlSchedule, {
      method: 'GET',
    })
    const newSchedules = yield res.json()

    if (newSchedules?.data && !newSchedules?.data.active) {
      navigation.navigate(`/checkout/${get(course, 'id')}/confirm`)
      return showError(
        'This schedule is unavailable, please select a new schedule.'
      )
    }
  }
  const body = {
    channel_id: course.id,
    partner_id: currentUser.partner_id,
    user_id: user_id,
    schedule_id: get(schedule, 'id', 0),
    is_send: isSend,
  }

  if (!schedule && !body.schedule_id && enrollID) {
    body.schedule_id = get(partners, '[0].schedule.id', 0)
  }
  if (!requiredSchedule) {
    delete body.schedule_id
  }

  if (enrollID) {
    body.id = enrollID
  }

  if (ref || userAccountRef) {
    const res = yield call(getUserById, ref || userAccountRef)
    const user = get(res, 'data[0]', null)
    const isSeller = userType(
      Number(process.env.REACT_APP_SALES_GROUP_ID),
      get(user, 'user_groups', [])
    )
    if (isSeller) {
      body.ref_id = parseFloat(ref || userAccountRef)
    }
  }

  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    if (res.errors) {
      showError('This customer has already enrolled this course!')
      yield delay(200)
      yield put(hideGlobalLoading())
      return navigation.navigate(`/checkout/${get(course, 'id')}/fail`)
    }
    if (res.data.id) {
      yield put(actions.setRef(null))
      yield put(actions.loadCourse(course.id))
      yield delay(200)
      if (wfaDomain) {
        navigation.navigate(`/course/channel/${get(course, 'id')}`)
      } else {
        navigation.navigate(`/checkout/${get(course, 'id')}/complete`)
      }
    }
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onUpdateEnroll(action) {
  const requestURL = `${config.baseUrl}/api/lms/courses/channels/partners`
  const currentUser = action.params.currentUser
  const course = action.params.course
  const schedule = action.params.schedule

  const id = get(course, 'partners[0].id')
  const partners = get(course, 'partners[0]', {})
  if (partners && partners.schedule) {
    delete partners.schedule
  }
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({
        ...partners,
        id,
        channel_id: course.id,
        partner_id: currentUser.partner_id,
        user_id: currentUser.id,
        schedule_id: schedule,
      }),
    })

    if (res.errors) {
      yield delay(200)
      yield put(hideGlobalLoading())
    }
    if (res.data.id) {
      yield put(
        loadListCourse({
          partner: get(currentUser, 'partner_id'),
        })
      )
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onDeleteEnroll(action) {
  const enrollment = action.params.enrollment
  const id = get(enrollment, 'partners[0].id')
  const requestURL = `${config.baseUrl}/api/lms/courses/channels/partners/${id}`

  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'DELETE',
    })

    yield put(
      loadListCourse({
        partner: get(enrollment, 'partners[0].partner_id'),
        admin: true,
        filter: 'sequence',
        newFilter: 'per_page=1000&ignore_per_page=true&include_delete=true',
      })
    )
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onGetAnswerTime(action) {
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  const userId = get(currentUser, 'uid', '') || get(currentUser, 'id', '')

  if (!userId) {
    return
  }
  const requestURL = `${config.baseUrl}/api/users/${userId}/answer-times`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    if (Array.isArray(res.data)) {
      yield put(actions.getAnswerTimeSuccess(res.data))
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onPutAnswerTime(action) {
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  const selectedSlide = yield select((state) =>
    selectors.makeSelectSelectedSlide()(state)
  )
  const slideId = get(selectedSlide, 'id', '')
  const userId = get(currentUser, 'uid', '') || get(currentUser, 'id', '')
  const startDate = get(action, 'params.startDate', '')
  const endDate = get(action, 'params.endDate', '')
  const answerId = get(action, 'params.answerQuizId', '')
  const needToComplete = action.params.needToComplete
  const requestURL = `${config.baseUrl}/api/users/${userId}/answer-times`

  if (!userId) {
    return showError('You are not login yet!')
  }
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(
        endDate && answerId
          ? { slide_id: slideId, end_date: endDate, id: answerId }
          : {
              slide_id: slideId,
              start_date: startDate,
            }
      ),
    })

    if (res.data.id) {
      yield put(actions.getAnswerTime())
      if (needToComplete) {
        const params = {
          end_date: dayjs(),
          completed: true,
        }
        yield put(actions.setPreviewComplete(params))
      }
    }
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onGetUserPaymentMethod(action) {
  const courseId = action.params.courseId
  const isSFC = action.params.isSFC
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  let userId = get(currentUser, 'uid', '') || get(currentUser, 'id', '')

  if (action.params.userId) {
    userId = action.params.userId
  }

  if (!userId) {
    return
  }
  let requestURL = `${config.baseUrl}/api/users/${userId}/payment-method?order_by=id%20desc`

  if (courseId) {
    requestURL = `${config.baseUrl}/api/users/${userId}/payment-method?channel_id=${courseId}`
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data) && isSFC) {
      const result = res.data.filter((item) => item.payment_method === 'credit')
      return yield put(actions.getUserPaymentMethodSuccess(result))
    }

    if (Array.isArray(res.data)) {
      yield put(actions.getUserPaymentMethodSuccess(res.data))
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onGetUserInfo(action) {
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  const userId = get(currentUser, 'uid', '') || get(currentUser, 'id')
  const course = yield select((state) => makeSelectCourse()(state))
  const domain = yield select((state) => selectors.makeSelectDomain()(state))

  if (!userId) {
    return yield put(actions.getUserInfoFailed({}))
  }
  const requestURL = `${config.baseUrl}/api/users/${userId}/profiles`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data)) {
      const profileDomainID = !isEmpty(course) ? course.website_id : domain?.id

      yield put(
        actions.getUserInfoSuccess(getProfile(profileDomainID, res.data))
      )
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onGetUserLog(action) {
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  const userId = get(currentUser, 'uid', '') || get(currentUser, 'id')
  const channelID = action.params.channel_id || 0
  const scheduleID = action.params.schedule_id || 0

  if (!userId) {
    return
  }
  const requestURL = `${config.baseUrl}/api/users/${userId}/reports/channels?order_by=id%20asc&channel_id=${channelID}&schedule_id=${scheduleID}`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data)) {
      const data = get(res, 'data', [])
      yield put(actions.getUserLogSuccess(data))
      yield put(actions.putUserLogSuccess(null))
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onPutUserLog(action) {
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  const userId = get(currentUser, 'uid', '') || get(currentUser, 'id')
  const channel_id = parseInt(action.params.channel_id) || 0
  const schedule_id = action.params.schedule_id || 0
  const access_count = action.params.access_count + '' || ''
  const course_start = action.params.course_start || ''
  const course_completion_date = action.params.course_completion_date || ''
  const course_duration = action.params.course_duration || ''
  const id = action.params.id

  const needToLoadLog = action.needToLoadLog
  const body = {
    id,
    user_id: userId,
    channel_id,
    schedule_id,
    access_count,
    course_start,
    course_completion_date,
    course_duration,
  }

  if (!access_count) {
    delete body.access_count
  }
  if (!course_start) {
    delete body.course_start
  }
  if (!course_completion_date) {
    delete body.course_completion_date
  }

  if (!course_duration) {
    delete body.course_duration
  }

  if (!id) {
    delete body.id
  }

  if (!userId) {
    return
  }
  const requestURL = `${config.baseUrl}/api/users/${userId}/reports/channels`
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(body),
    })

    if (res.data) {
      yield put(actions.putUserLogSuccess(res.data))
      if (needToLoadLog) {
        yield put(actions.getUserLog({ channel_id, schedule_id }))
      }
    }
  } catch (err) {
    console.log('err', err)
  }
}

export default function* homeSaga() {
  yield takeLatest(constants.LOAD_COURSE, onLoadCourse)
  yield takeLatest(constants.LOAD_LIST_COURSE, onLoadListCourse)
  yield takeLatest(constants.LOAD_LIST_CATEGORY, onLoadListCategory)
  yield takeLatest(constants.LOAD_SLIDES, onLoadSlides)
  yield takeLatest(constants.LOAD_SLIDE_DETAILS, onLoadSlideDetails)
  yield takeLatest(constants.SET_PREVIEW_COMPLETE, onSetPreviewComplete)
  yield takeLatest(constants.POST_USER_ANSWER, onPostUserAnswer)
  yield takeLatest(constants.LOAD_USER_ANSWER, onLoadUserAnswer)
  yield takeLatest(constants.LOAD_DOMAIN, onLoadDomain)
  yield takeLatest(constants.LOAD_USER_LOCATION, onLoadUserLocation)
  yield takeLatest(constants.POST_ENROLL, onPostEnroll)
  yield takeLatest(constants.UPDATE_ENROLL, onUpdateEnroll)
  yield takeLatest(constants.DELETE_ENROLL, onDeleteEnroll)
  yield takeLatest(constants.GET_ANSWER_TIME, onGetAnswerTime)
  yield takeLatest(constants.PUT_ANSWER_TIME, onPutAnswerTime)
  yield takeLatest(constants.GET_USER_PAYMENT_METHOD, onGetUserPaymentMethod)
  yield takeLatest(constants.GET_USER_INFO, onGetUserInfo)
  yield takeLatest(constants.GET_USER_LOG, onGetUserLog)
  yield takeLatest(constants.PUT_USER_LOG, onPutUserLog)
  yield takeLatest(
    constants.LOAD_PREVIEW_BY_CHANNEL_ID,
    onLoadPreviewByChannelId
  )
}
