import React, { useEffect, useState } from 'react'
import { parse } from 'query-string'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import TableCourses, { eliminateProperty } from '../../components/TableCourses'
import TableCoursesClient from '../../components/TableCoursesClient'
import FilterCourses from 'src/component/FilterCourses'
import HeaderAdminCourses from './HeaderAdminCourses'
import {
  loadListCourse,
  setSelectedSlide,
  loadSlideDetailsSuccess,
  loadCourseSuccess,
  loadSlidesSuccess,
} from 'container/Home/actions'
import { makeSelectCourseType } from '../../selectors'
import { makeSelectListCourse } from 'container/Home/selectors'
import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import {
  getUserByCourseAndScheduleSuccess,
  loadListUserAnswerSuccess,
  setSelectedSection,
} from '../../actions'

import './styles.scss'
import { AdminLayout } from 'src/styles'
import { isEmpty } from 'lodash'
import useGetClientAdmin from 'src/hooks/useGetClientAdmin'

const filterOptions = [
  {
    text: `All Courses`,
    value: 'sequence',
  },
  { text: 'Published', value: 'total_votes' },
  { text: 'Unpublished', value: 'create_date' },
]

const Courses = () => {
  const { search, pathname } = useLocation()
  const { page = 1, perpage = 15, name } = parse(search)
  const history = useHistory()
  const parsedSearch = parse(search)

  const dispatch = useDispatch()
  const courses = useSelector(makeSelectListCourse())
  const courseType = useSelector(makeSelectCourseType())
  const [filter, setFilter] = useState('sequence')
  const [sorting, setSorting] = useState(null)
  const { isClientAdmin } = useGetClientAdmin()

  useEffect(() => {
    dispatch(setSelectedSlide(null))
    dispatch(loadSlideDetailsSuccess(null))
    dispatch(loadSlidesSuccess(null))
    dispatch(loadCourseSuccess(null))
    dispatch(loadListUserAnswerSuccess(null))
    dispatch(getUserByCourseAndScheduleSuccess(null))
  }, [])

  useEffect(() => {
    if (filter) {
      dispatch(
        loadListCourse({
          filter,
          page,
          perpage,
          admin: true,
          sorting,
          keyword: name,
        })
      )
    }
  }, [filter, page, perpage, sorting, name])

  useEffect(() => {
    let tmpSorting
    if (!isEmpty(parsedSearch)) {
      tmpSorting = eliminateProperty({ ...parsedSearch, page: 0, perpage: 0 })
      setSorting(!isEmpty(tmpSorting) ? tmpSorting : null)
    }
  }, [search])

  const handleCreateNewCourse = () => {
    dispatch(setSelectedSection(null))
    dispatch(setSelectedSlide(null))
    dispatch(loadCourseSuccess(null))
    dispatch(loadSlidesSuccess(null))
  }

  return (
    <>
      <HeaderAdminCourses
        handleCreateNewCourse={handleCreateNewCourse}
        isClientAdmin={isClientAdmin}
      />
      <AdminLayout>
        <div className="container_left">
          <FilterCourses
            setFilter={setFilter}
            filter={filter}
            filterOptions={filterOptions}
            history={history}
            pathname={pathname}
            adminCourses
          />
        </div>

        <div className="container_right">
          {isClientAdmin ? (
            <TableCoursesClient
              courseType={courseType}
              page={page}
              perPage={perpage}
              dispatch={dispatch}
            />
          ) : courses && courses.length !== 0 ? (
            <TableCourses
              courses={courses}
              courseType={courseType}
              page={page}
              perPage={perpage}
              dispatch={dispatch}
            />
          ) : (
            <div className="empty_course">
              <img src={images.admin.empty_course} alt="icon" />
              <h1>Create a new course</h1>
              <p>
                You don’t have a course set up yet.
                <br /> Get started by creating a new course.
              </p>
              <Link to="admin/course/channel/new">
                <BtnPrimary
                  name="Create New Course"
                  style={{
                    backgroundColor: '#F6F6F9',
                    fontWeight: 'bold',
                    color: '#27284D',
                    padding: '12px 14px',
                  }}
                  handleClick={handleCreateNewCourse}
                  iconLeft={images.admin.plus}
                />
              </Link>
            </div>
          )}
        </div>
      </AdminLayout>
    </>
  )
}

export default Courses
