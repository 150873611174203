import React from 'react'
import images from 'Themes/Images'
import useComponentVisible from 'utils/hooks'
import { useSelector } from 'react-redux'
import { DOMAIN } from '../../routes'
import { Link } from 'react-router-dom'
import { makeSelectDomain } from 'container/Home/selectors'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { isTrainerRole } from 'utils/userUtils'
import { isLoginAsRoleAdmin, isMainAdmin } from 'utils/helper'

const Menu = ({ isOnAdmin, isSuperAdmin, preview }) => {
  const domain = useSelector(makeSelectDomain())
  const isAscendo =
    domain?.domain === DOMAIN.ASCENDO_2 || domain?.domain === DOMAIN.ASCENDO
  const currentUser = useSelector(makeSelectCurrentUser())
  const userGroups = currentUser?.user_groups || []
  const isTrainer = isTrainerRole(userGroups)
  const isAdminForAscendo = isLoginAsRoleAdmin(currentUser)

  const isAdmin = isMainAdmin(currentUser)
  const isOnlyTrainer = isTrainer && !isAdminForAscendo

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  if (!isOnAdmin && !preview && isAdmin) {
    return (
      <div
        className="navbar__wrap-menu"
        ref={ref}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <img src={images.header.bars} alt="Menu" className="menu__icon" />
        Menu
        {isComponentVisible && (
          <ul className="navbar__menu-list">
            <li className="navbar__menu-item">
              <Link to="/?page=1&perpage=15">Browse Courses</Link>
            </li>
            <li className="navbar__menu-item">
              <Link to="/admin">Admin Management</Link>
            </li>
          </ul>
        )}
      </div>
    )
  }

  if (!isOnAdmin && !preview) {
    return (
      <div
        className="navbar__wrap-menu"
        ref={ref}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <img src={images.header.bars} alt="Menu" className="menu__icon" />
        Menu
        {isComponentVisible && (
          <ul className="navbar__menu-list">
            <li className="navbar__menu-item">
              <Link to="/?page=1&perpage=15">Browse Courses</Link>
            </li>
          </ul>
        )}
      </div>
    )
  }

  const renderMenu = () => {
    if (isSuperAdmin) {
      return (
        <ul className="navbar__menu-list">
          <li className="navbar__menu-item">
            <Link to="/admin?page=1&perpage=15">Leads Page</Link>
          </li>
          <li className="navbar__menu-item">
            <Link to="/admin/user-management">User Management</Link>
          </li>
        </ul>
      )
    }

    if (isOnlyTrainer) {
      return (
        <ul className="navbar__menu-list">
          <li className="navbar__menu-item">
            <Link to="/?page=1&perpage=15">Published Courses</Link>
          </li>
          <li className="navbar__menu-item">
            <Link to="/admin/user-management">User Management</Link>
          </li>
          <li className="navbar__menu-item">
            <Link to="/admin?page=1&perpage=15">Manage Courses</Link>
          </li>
        </ul>
      )
    }

    return (
      <ul className="navbar__menu-list">
        <li className="navbar__menu-item">
          <Link to="/admin/settings?route=branding">Settings</Link>
        </li>
        <li className="navbar__menu-item">
          <Link to="/?page=1&perpage=15">Published Courses</Link>
        </li>
        <li className="navbar__menu-item">
          <Link to="/admin?page=1&perpage=15">Manage Courses</Link>
        </li>
        <li className="navbar__menu-item">
          <Link to="/admin/user-management">User Management</Link>
        </li>
        {!!isAscendo && isAdmin && (
          <li className="navbar__menu-item">
            <Link to="/admin/class-management">Class Management</Link>
          </li>
        )}
        {!!isAscendo && isAdminForAscendo && (
          <li className="navbar__menu-item">
            <Link to="/admin/client-management">Client Management</Link>
          </li>
        )}
      </ul>
    )
  }

  return (
    (isOnAdmin || preview) && (
      <div
        className="navbar__wrap-menu"
        ref={ref}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <img src={images.header.bars} alt="Menu" className="menu__icon" />
        Menu
        {isComponentVisible && renderMenu()}
      </div>
    )
  )
}

export default Menu
