import React, { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player/youtube'
import * as Yup from 'yup'
import classNames from 'classnames'
import Dropdown from 'react-dropdown'
import { uploadImage } from 'src/container/CMS/hooks.js'

import TextEditor from 'src/customs/ClassCompare/src/components/Editor'
import { CKEditorWrapper } from 'container/CMS/screens/Article'
import { mapListCategoryToDropDown } from 'utils/helper'
import { useParams } from 'react-router-dom'
import { Radio, Switch } from 'antd'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import images from 'Themes/Images'
import Input from 'component/Input'
import * as utilHelpers from 'utils/helper'
import InputSmall from 'component/InputSmall'
import { ERROR_MESSAGE } from 'utils/constants'
import { createCourse } from 'container/Admin/actions'
import { makeSelectCourse } from 'container/Home/selectors'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { makeSelectCourseType } from 'container/Admin/selectors'
import { makeSelectListCategory } from 'container/Home/selectors'
import { parseOptionsPrice } from 'utils/helper'
import OptionsPricing from 'component/OptionsPricing'
import SFCOptionsPrice from 'component/SFCOptionsPrice'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import { useMediaQuery } from 'react-responsive'
import { BannerDetail } from 'src/customs/ClassCompare/styles'
import config from 'src/global-config'
import './styles.scss'
import SentenceList from '../../../SentenceList'
import { makeSelectDomain } from 'container/Home/selectors'
import { DOMAIN } from 'src/routes'
import useGetTrainer from '../../../../../../hooks/useGetTrainer'

const CourseSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
  owner: Yup.string().required(ERROR_MESSAGE),
})

const initialValue = [{ id: 0, text: '' }]

const AddCourse = ({ setFilter, saveFunction, saveGo, saveRef }) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(makeSelectCurrentUser())
  const courseType = useSelector(makeSelectCourseType())
  const categories = useSelector(makeSelectListCategory())
  const domain = useSelector(makeSelectDomain())
  const [file, setFile] = useState(null)
  const [validUrl, setValidUrl] = useState(false)
  const [radio, setRadio] = useState(1)
  const [allowApplicationFee, setAllowApplicationFee] = useState(false)
  const [allowCourseValidity, setAllowCourseValidity] = useState(false)
  const [requiredSchedule, setRequiredSchedule] = useState(false)
  const [showSeatsLeft, setShowSeatsLeft] = useState(false)
  const [courseVisible, setCourseVisible] = useState(false)
  const [lockedLessons, setLockedLessons] = useState(false)
  const [allowSpecialPrice, setAllowSpecialPrice] = useState(false)
  const [editTitle, setEditTitle] = useState(false)
  const [togglePricing, setTogglePricing] = useState(true)
  const [toggleAdditional, setToggleAdditional] = useState(true)
  const [toggleSkillsfuture, setToggleSkillsfuture] = useState(true)
  const [benefits, setBenefits] = useState(initialValue)
  const [requirements, setRequirements] = useState(initialValue)

  const hiddenFileInput = useRef(null)
  const { id } = useParams()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isBellDomain = get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION

  const course = useSelector(makeSelectCourse())

  const formik = useFormik({
    initialValues: {
      name: '',
      owner: '',
      price: '',
      max_size: '',
      totalTime: '',
      course_code: '',
      description: '',
      preview_url: '',
      special_price: '',
      proficiency_id: '',
      proficiency_name: '',
      applicationFee: 0,
      category: null,
      prices: [],
      sfc_prices: [],
      short_description: '',
      enroll_msg: '',
      course_validity: 0,
    },
    validationSchema: CourseSchema,

    onSubmit: async (values) => {
      const price = parseFloat(values.price)
      const specialPrice = parseFloat(values.special_price)

      if (allowSpecialPrice && specialPrice && price && specialPrice >= price) {
        return formik.setFieldError(
          'special_price',
          'Special price can not greater than price'
        )
      }

      const old_price_ids = []
      const prices = values.prices.map((price) => {
        if (price.id) {
          old_price_ids.push(price.id)
        }
        return {
          title: price.title,
          description: price.description,
          price: parseFloat(price.price),
          channel_id: parseFloat(id),
        }
      })

      const old_sfc_price_ids = []
      const sfc_prices = values.sfc_prices.map((price) => {
        if (price.id) {
          old_sfc_price_ids.push(price.id)
        }
        const priceOptions = parseOptionsPrice(price.price_options).map(
          (item) => ({
            title: item.title,
            price: item.price,
          })
        )
        return {
          course_code: price.course_code,
          price_options: JSON.stringify(priceOptions),
          channel_id: parseFloat(id),
        }
      })

      let payload = {
        price: price,
        name: values.name,
        description: values.description,
        course_type: radio,
        image_url: get(file, 'image_url', ''),
        is_published: get(course, 'is_published', false),
        total_days: parseFloat(values.totalTime),
        proficieny_name: values.proficiency_name,
        proficieny_id: values.proficiency_id,
        course_code: values.course_code,
        max_size: parseInt(values.max_size),
        preview_url: values.preview_url,
        id: Number.isInteger(parseInt(id)) ? parseInt(id) : '',
        prices,
        old_price_ids,
        sfc_prices,
        old_sfc_price_ids,
        short_description: values.short_description,
        category_id: get(values, 'category.value') || 0,
        website_id: get(course, 'website_id'),
        is_priority: get(course, 'is_priority', false),
        enroll_msg: values.enroll_msg,
        is_schedule: requiredSchedule,
        is_show_seats_left: showSeatsLeft,
        locked_lessons: lockedLessons,
        course_visibility: courseVisible,
        course_validity: allowCourseValidity
          ? parseInt(values.course_validity)
          : 0,
        course_requirements: JSON.stringify(requirements),
        course_benefits: JSON.stringify(benefits),
      }

      if (!values.category) {
        payload.category_id = 0
      }

      if (!payload.id) {
        delete payload.id
      }

      if (allowApplicationFee) {
        payload.is_application_fee = true
        payload.application_fee = parseFloat(values.applicationFee)
      } else {
        payload.is_application_fee = false
      }

      if (allowSpecialPrice) {
        payload.is_allow_special_price = true
        payload.special_price = parseFloat(values.special_price)
      } else {
        payload.is_allow_special_price = false
      }

      dispatch(createCourse(payload, saveGo, setFilter))

      setEditTitle(false)
    },
  })

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      dispatch(showGlobalLoading())
      const imageUrl = await uploadImage(fileUploaded)
      if (imageUrl) {
        setFile({
          name: imageUrl.name,
          image_url: imageUrl.url,
          size: fileUploaded.size,
        })
      }
      formik.submitForm()
      dispatch(hideGlobalLoading())
    }
  }

  const handleRemove = () => {
    setFile(null)
  }

  const onSelect = (cat) => {
    formik.setFieldValue('category', cat)
  }

  useEffect(() => {
    if (currentUser) {
      formik.setFieldValue('owner', currentUser.name)
    }

    if (course) {
      formik.setFieldValue('name', course.name)
      formik.setFieldValue('description', course.description)
      formik.setFieldValue('enroll_msg', course.enroll_msg)
      const channelTags = get(course, 'channel_tags', [])
      const isAllowApplicationFee = get(course, 'is_application_fee', false)
      const isAllowSpecialPrice = get(course, 'is_allow_special_price', false)
      const applicationFee = get(course, 'application_fee', 0)
      const categoryId = get(course, 'category_id') || null
      const shortDescription = get(course, 'short_description', '')
      const proficienyName = get(course, 'proficieny_name', '')
      setRequiredSchedule(get(course, 'is_schedule', false))
      setShowSeatsLeft(get(course, 'is_show_seats_left', false))
      setCourseVisible(get(course, 'course_visibility', false))
      if (course.course_validity > 0) {
        formik.setFieldValue('course_validity', course.course_validity)
        setAllowCourseValidity(true)
      } else {
        setAllowCourseValidity(false)
        formik.setFieldValue('course_validity', 0)
      }
      setLockedLessons(get(course, 'locked_lessons'))
      if (isAllowApplicationFee) {
        setAllowApplicationFee(true)
        formik.setFieldValue('applicationFee', applicationFee)
      }

      if (isAllowSpecialPrice) {
        setAllowSpecialPrice(true)
      }
      const category =
        categories && categories.find((cat) => cat.id === categoryId)

      if (category) {
        formik.setFieldValue('category', get(category, 'name', ''))
      }

      formik.setFieldValue('prices', get(course, 'prices', []))
      formik.setFieldValue('sfc_prices', get(course, 'sfc_prices', []))
      formik.setFieldValue('totalTime', get(course, 'total_days', ''))
      formik.setFieldValue('proficiency_id', get(course, 'proficieny_id', ''))
      formik.setFieldValue('course_code', get(course, 'course_code', ''))
      formik.setFieldValue('max_size', get(course, 'max_size'))
      formik.setFieldValue('special_price', get(course, 'special_price', ''))
      formik.setFieldValue('price', get(course, 'price', ''))
      formik.setFieldValue('preview_url', get(course, 'preview_url'))
      formik.setFieldValue('short_description', shortDescription)
      formik.setFieldValue('proficiency_name', proficienyName)

      setFile({ image_url: get(course, 'image_url', '') })
      setRadio(get(course, 'course_type'))

      const courseRequirements = utilHelpers.parseSafe(
        get(course, 'course_requirements', '')
      )
      setRequirements(courseRequirements || initialValue)

      const courseBenefits = utilHelpers.parseSafe(
        get(course, 'course_benefits', '')
      )
      setBenefits(courseBenefits || initialValue)
    }
  }, [currentUser, course])

  useEffect(() => {
    if (formik) {
      saveFunction(formik)
    }
  }, [formik])

  const handleChangeInputNumber = (e, field) => {
    if (utilHelpers.checkInput(e)) {
      formik.setFieldValue(field, e.currentTarget.value)
    }
  }

  const renderImage = () => {
    if (file && file.image_url) {
      return file.image_url + '=s0'
    }

    return images.admin.course_img_xample
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      return saveRef?.current?.submitForm()
    }
  }

  const { isTrainer } = useGetTrainer()

  return (
    <form onSubmit={formik.handleSubmit} className="admin__channel__body">
      <div
        className="channel__head"
        style={{
          borderBottom: '1px solid #d0cad8',
        }}
      >
        <div
          className="inner_container"
          style={{
            display: isMobile ? 'block' : 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <div className="channel__head__left" style={{ width: '100%' }}>
            <div className="channel__chapter">COURSE TITLE*</div>
            <div
              className="channel__title"
              style={{ width: '90%', marginTop: 10 }}
              onClick={() => setEditTitle(true)}
            >
              {course && !editTitle ? (
                <div className="admin__channel__course-name">
                  {course.name}
                  <img src={images.admin.pen_alt_active} alt="" />
                </div>
              ) : (
                <input
                  id="name"
                  name="name"
                  placeholder="Course Title"
                  type="text"
                  autoComplete="off"
                  className={`add-course__input ${
                    formik.touched.name && formik.errors.name
                      ? 'form__input__error'
                      : ''
                  }`}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onKeyDown={handleKeyDown}
                />
              )}
            </div>
            {formik.touched.name && formik.errors.name && (
              <div className="form__error">{formik.errors.name}</div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          borderTop: 'unset',
          display: 'block',
        }}
      >
        <div
          className="channel__left admin__channel__left"
          style={{ padding: 0 }}
        >
          <div className="add-course__heading" style={{ borderTop: 'unset' }}>
            Course details
          </div>
          <div
            className="add-course__head"
            style={{ padding: '0 24px', textTransform: 'unset' }}
          >
            COVER PHOTO* (Recommended image size at least 960x540px resolution)
          </div>
          <div className="inner_container">
            <div
              className={`add-course__upload-wrap-first ${
                isTrainer ? 'disabledbutton' : ''
              }`}
            >
              <div style={{ width: isMobile ? '100%' : 960 }}>
                <BannerDetail>
                  <img src={renderImage()} />
                </BannerDetail>
              </div>
              <div className="add-course__file-type">
                {file && file.name && (
                  <div className="add-course__file-name"> {file.name}</div>
                )}
                {file && file.size && (
                  <div className="add-course__file-size">
                    {file.size + 'kb'}
                  </div>
                )}
                <div
                  className={`add-course__file-upload ${
                    isTrainer ? 'disabledbutton' : ''
                  }`}
                >
                  <button
                    className="tertiary-btn"
                    type="button"
                    onClick={handleClick}
                    style={{ width: '111px!important' }}
                  >
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={hiddenFileInput}
                      onChange={handleChange}
                    />
                    <img src={images.header.image_none_bg} alt="" />
                    <span>
                      {file && file.image_url ? 'Reupload' : 'Upload'}
                    </span>
                  </button>
                </div>
                <div className="add-course__file-remove" onClick={handleRemove}>
                  <button className="tertiary-btn" type="button">
                    <img src={images.admin.trash_icon} alt="" />{' '}
                    <span>Discard</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="add-course__description-first pdB-0">
              <div className="add-course__head">Short Description</div>
              <textarea
                className="add-course__textarea mgbt-0"
                id="description"
                name="description"
                cols="20"
                rows="8"
                style={{ width: '100%' }}
                placeholder="Enter short description"
                value={formik.values.short_description}
                onChange={formik.handleChange('short_description')}
              ></textarea>
            </div>

            <div className="add-course__description-first">
              <div className="add-course__head">Video Preview</div>
              <Input
                name="preview_url"
                placeHolder="Enter Youtube or Vimeo link"
                formik={formik}
                value={formik.values.preview_url}
                onChange={formik.handleChange}
                autoComplete="off"
                style={{ width: '100%' }}
              />
              {utilHelpers.isYoutubeUrl(formik.values.preview_url) && (
                <div
                  style={{
                    marginTop: 20,
                    position: 'relative',
                    height: validUrl ? '400px' : '0px',
                  }}
                >
                  <ReactPlayer
                    onReady={() => {
                      setValidUrl(true)
                    }}
                    className="react-player"
                    url={formik.values.preview_url}
                    width="84%"
                    height="100%"
                    controls={true}
                  />
                </div>
              )}
            </div>

            <div className="add-course__description-first">
              <div className="add-course__head">COURSE DESCRIPTION*</div>
              <CKEditorWrapper zIndex={100} top={77}>
                <TextEditor
                  value={formik.values.description}
                  onChange={(data) => {
                    formik.setFieldValue('description', data)
                  }}
                ></TextEditor>
              </CKEditorWrapper>
            </div>

            <div className="add-course__description-first">
              <div className="add-course__head">What you'll learn</div>
              <SentenceList listArr={benefits} setListArr={setBenefits} />
            </div>
            <div className="add-course__description-first">
              <div className="add-course__head">Requirements</div>
              <SentenceList
                listArr={requirements}
                setListArr={setRequirements}
              />
            </div>
          </div>

          <div
            className="add-course__heading"
            style={{ display: 'flex', cursor: 'pointer' }}
            onClick={() => setTogglePricing(!togglePricing)}
          >
            <div
              className="inner_container"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>Pricing</div>
              <div style={{ paddingRight: isMobile ? 0 : 50 }}>
                {!togglePricing ? (
                  <img src={images.minus} width="12" />
                ) : (
                  <img src={images.plus} width="12" />
                )}
              </div>
            </div>
          </div>
          <div
            className={classNames('inner_container', {
              hide_settings: togglePricing,
              show_settings: !togglePricing,
            })}
            style={{ transition: 'all 0.5s ease', overflow: 'hidden' }}
          >
            <div className="add-course__pricing">
              <div className="add-course__pricing__left">
                <InputSmall
                  className={
                    formik.touched.price && formik.errors.price
                      ? 'form__input__error'
                      : ''
                  }
                  text="course price*"
                  iconLeft={images.admin.sgd}
                  onChange={(e) => handleChangeInputNumber(e, 'price')}
                  value={formik.values.price}
                  type="text"
                />
                {formik.touched.price && formik.errors.price && (
                  <div className="form__error">{formik.errors.price}</div>
                )}
              </div>
              <div className="add-course__pricing__right">
                <InputSmall
                  className={
                    formik.touched.special_price && formik.errors.special_price
                      ? 'form__input__error'
                      : ''
                  }
                  text="course special price"
                  iconLeft={images.admin.sgd}
                  onChange={(e) => handleChangeInputNumber(e, 'special_price')}
                  value={formik.values.special_price}
                  type="text"
                  allowApplicationFee={allowSpecialPrice}
                />
                {formik.touched.special_price &&
                  formik.errors.special_price && (
                    <div className="form__error">
                      {formik.errors.special_price}
                    </div>
                  )}

                <div
                  className="add-course__switch switch_wrap"
                  style={{ marginTop: 15, marginBottom: 0 }}
                >
                  <Switch
                    size={25}
                    checked={allowSpecialPrice}
                    onChange={(checked) => setAllowSpecialPrice(checked)}
                  />
                  <div
                    className={classNames('add-course__switch__label', {
                      'application-fee__text-active': allowApplicationFee,
                    })}
                  >
                    Allow special price
                  </div>
                </div>
              </div>
            </div>
            <div className="add-course__pricing" style={{ paddingBottom: 10 }}>
              <OptionsPricing
                prices={formik.values.prices}
                formik={formik}
                course={course}
              />
            </div>
            <div
              className="add-course__pricing"
              style={{ paddingBottom: 10, paddingTop: 0 }}
            >
              <div className="add-course__pricing__left">
                <InputSmall
                  text="Total Time (HOURS)*"
                  iconLeft={images.admin.clock_sm}
                  onChange={(e) => handleChangeInputNumber(e, 'totalTime')}
                  value={formik.values.totalTime}
                  type="text"
                />
              </div>
              <div className="add-course__pricing__right"></div>
            </div>

            <div className="add-course__pricing" style={{ paddingBottom: 5 }}>
              <div className="add-course__pricing__left">
                <InputSmall
                  text="Max class size (OPTIONAL)"
                  iconLeft={images.admin.user}
                  onChange={(e) => handleChangeInputNumber(e, 'max_size')}
                  value={formik.values.max_size ? formik.values.max_size : ''}
                  type="text"
                  name="max_size"
                  formik={formik}
                />
              </div>
              <div className="add-course__pricing__right"></div>
            </div>

            <div className="add-course__pricing" style={{ paddingBottom: 10 }}>
              <div className="add-course__switch switch_wrap">
                <Switch
                  size={25}
                  checked={allowApplicationFee}
                  onChange={(checked) => setAllowApplicationFee(checked)}
                />
                <div
                  className={classNames('add-course__switch__label', {
                    'application-fee__text-active': allowApplicationFee,
                  })}
                >
                  Collect Application Fee
                </div>
              </div>
            </div>
            <div className="add-course__pricing" style={{ paddingTop: 0 }}>
              <div className="application-fee">
                <>
                  <InputSmall
                    className={
                      formik.touched.price && formik.errors.price
                        ? 'form__input__error'
                        : ''
                    }
                    text="Application FEE"
                    iconLeft={images.admin.sgd}
                    onChange={(e) =>
                      handleChangeInputNumber(e, 'applicationFee')
                    }
                    value={formik.values.applicationFee}
                    type="text"
                    allowApplicationFee={allowApplicationFee}
                  />
                  {formik.touched.price && formik.errors.price && (
                    <div className="form__error">{formik.errors.price}</div>
                  )}
                </>
                <span
                  className={classNames('application-fee__text', {
                    'application-fee__text-active': allowApplicationFee,
                  })}
                >
                  By collecting application fee, participants will be required
                  to pay the application fee instead of the course price.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="add-course__heading"
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => setToggleAdditional(!toggleAdditional)}
        >
          <div
            className="inner_container"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>Additional Settings</div>
            <div style={{ paddingRight: isMobile ? 0 : 50 }}>
              {!toggleAdditional ? (
                <img src={images.minus} width="12" />
              ) : (
                <img src={images.plus} width="12" />
              )}
            </div>
          </div>
        </div>

        <div
          className={classNames('inner_container', {
            hide_settings: toggleAdditional,
            show_settings: !toggleAdditional,
          })}
          style={{ transition: 'all 0.5s ease', overflow: 'hidden' }}
        >
          <div className="add-course__right-top">
            <div
              className="add-course__head"
              style={{ marginBottom: 8, marginTop: 0 }}
            >
              COURSE ACCESS SETTINGS
            </div>
            <div className="add-course__switch switch_wrap">
              <Switch
                size={25}
                checked={courseVisible}
                onChange={(checked) => setCourseVisible(checked)}
              />
              <div
                className={classNames('add-course__switch__label', {
                  'application-fee__text-active': courseVisible,
                })}
              >
                Enable to make course private and visible to enrolled users only
              </div>
            </div>

            <div
              className="add-course__pricing"
              style={{ paddingBottom: 10, paddingLeft: 0 }}
            >
              <div className="add-course__switch switch_wrap">
                <Switch
                  size={25}
                  checked={allowCourseValidity}
                  onChange={(checked) => setAllowCourseValidity(checked)}
                />
                <div
                  className={classNames('add-course__switch__label', {
                    'application-fee__text-active': allowCourseValidity,
                  })}
                >
                  Enable course validity period to limit access to course from
                  time of enrollment
                </div>
              </div>
            </div>

            <div className="course-validity">
              <>
                <InputSmall
                  className={
                    formik.touched.price && formik.errors.price
                      ? 'form__input__error'
                      : ''
                  }
                  onChange={(e) =>
                    handleChangeInputNumber(e, 'course_validity')
                  }
                  value={formik.values.course_validity}
                  style={{
                    width: 60,
                    padding: 0,
                    paddingRight: 10,
                    paddingLeft: 10,
                  }}
                  styleWrapper={{ width: 0 }}
                  type="text"
                  allowApplicationFee={allowCourseValidity}
                />
              </>
              <span
                className={classNames('course-validity__text', {
                  'course-validity__text-active': allowCourseValidity,
                })}
              >
                Days
              </span>
            </div>

            <div className="add-course__head">COURSE COHORT SETTINGS</div>
            <div className="add-course__switch switch_wrap">
              <Switch
                size={25}
                checked={requiredSchedule}
                onChange={(checked) => setRequiredSchedule(checked)}
              />
              <div
                className={classNames('add-course__switch__label', {
                  'application-fee__text-active': requiredSchedule,
                })}
              >
                Enable scheduling for cohort based classes
              </div>
            </div>
            <div
              className="add-course__switch switch_wrap"
              style={{ paddingTop: 20 }}
            >
              <Switch
                size={25}
                checked={showSeatsLeft}
                onChange={(checked) => setShowSeatsLeft(checked)}
              />
              <div
                className={classNames('add-course__switch__label', {
                  'application-fee__text-active': showSeatsLeft,
                })}
              >
                Hide no. of seats left from previous page
              </div>
            </div>

            <div className="add-course__head">COURSE TYPE</div>
            <div className="add-course__radio">
              <Radio.Group
                onChange={(e) => setRadio(e.target.value)}
                value={radio}
                name="add-course__radio-type"
              >
                {courseType &&
                  courseType.map((type) => (
                    <Radio value={type.id} key={type.id}>
                      {type.name}
                    </Radio>
                  ))}
              </Radio.Group>
            </div>

            <div className="add-course__head">Lesson access settings</div>
            <div className="add-course__switch switch_wrap">
              <Switch
                size={25}
                checked={lockedLessons}
                onChange={(checked) => setLockedLessons(checked)}
              />
              <div
                className={classNames('add-course__switch__label', {
                  'application-fee__text-active': lockedLessons,
                })}
              >
                Lock remaining lessons until user has completed the latest
                lesson
              </div>
            </div>
            <div
              className="add-course__head"
              style={{ textTransform: 'unset' }}
            >
              COURSE CATEGORY{' '}
              <a
                target="_blank"
                href={`${config.baseUrl}/admin/settings?route=categories`}
                className="link_category"
              >
                (Add course categories)
              </a>
            </div>
            <div className="add-course__radio" style={{ width: 200 }}>
              {categories && (
                <Dropdown
                  options={mapListCategoryToDropDown(categories)}
                  onChange={onSelect}
                  value={formik.values.category}
                  placeholder="Select Category"
                  className="dropdownScheduleModal dropdown"
                />
              )}
            </div>

            <div
              className="add-course__head"
              style={{ textTransform: 'unset' }}
            >
              ENROLMENT COMPLETION TEXT{' '}
              <span> (Add a message at the end of the enrolment process)</span>
            </div>
            <CKEditorWrapper zIndex={100} top={77} minHeight={200}>
              <TextEditor
                value={formik.values.enroll_msg}
                onChange={(data) => {
                  formik.setFieldValue('enroll_msg', data)
                }}
              ></TextEditor>
            </CKEditorWrapper>
          </div>
        </div>

        <div
          className="add-course__heading"
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => setToggleSkillsfuture(!toggleSkillsfuture)}
        >
          <div
            className="inner_container"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>Skillsfuture SSG Settings</div>
            <div style={{ paddingRight: isMobile ? 0 : 50 }}>
              {!toggleSkillsfuture ? (
                <img src={images.minus} width="12" />
              ) : (
                <img src={images.plus} width="12" />
              )}
            </div>
          </div>
        </div>

        <div
          className={classNames('inner_container', {
            hide_settings: toggleSkillsfuture,
            show_settings: !toggleSkillsfuture,
          })}
          style={{ transition: 'all 0.5s ease', overflow: 'hidden' }}
        >
          <div className="add-course__right-top">
            <div>
              <div
                className={classNames('input-small__text')}
                style={{ marginBottom: 8 }}
              >
                <div>COURSE CODE (SSG)</div>
              </div>
              <div style={{ marginBottom: 18 }}>
                <SFCOptionsPrice
                  prices={formik.values.sfc_prices}
                  formik={formik}
                  course={course}
                  saveRef={saveRef}
                />
              </div>
            </div>
            <InputSmall
              text="PROFICIENCY ID (SSG)"
              onChange={formik.handleChange}
              value={formik.values.proficiency_id}
              type="text"
              name="proficiency_id"
              placeholder="Enter Proficiency ID"
              labelStyle={{ marginBottom: 15, display: 'block' }}
              style={{ textAlign: 'left', paddingLeft: 5, width: 200 }}
            />
            <InputSmall
              name="proficiency_name"
              text="PROFICIENCY NAME (SSG)"
              placeholder="Enter Proficiency Name"
              onChange={formik.handleChange}
              value={formik.values.proficiency_name}
              type="text"
              labelStyle={{
                marginBottom: 15,
                marginTop: 20,
                display: 'block',
              }}
              style={{ textAlign: 'left', paddingLeft: 10, width: 200 }}
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddCourse
