import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'antd'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import dayjs from 'dayjs'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import BtnPrimary from 'component/BtnPrimary'

import config from 'src/global-config'
import ProgressBtn from '../../components/ProgressBtn'
import CartSummary from '../../components/CartSummary'
import CardInformation from '../../components/CardInformation'
import CheckoutProgressBar from '../../components/CheckoutProgressBar'
import {
  isEnrollCourse,
  validateNric,
  getOptionPrice,
  formatMoney,
} from 'utils/helper'
import { showError } from 'utils/notification'
import { ERROR_MESSAGE } from 'utils/constants'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import {
  postEnroll,
  loadCourse,
  getUserInfo,
  getUserPaymentMethod,
} from 'container/Home/actions'
import { checkoutWithSFCPayment, setSelectedPriceSFC } from '../../actions'
import {
  makeSelectSelectedModuleSFC,
  makeSelectSelectedPriceSFC,
} from '../../selectors'
import { getAccessToken } from 'utils/request'
import { hideSelectPriceSFCModal } from 'container/Modal/actions'
import {
  makeSelectDomain,
  makeSelectRefId,
  makeSelectUserLocation,
} from 'container/Home/selectors'
import { paypalKey } from 'src/routes'
import { parseSafe, validateEmail } from 'utils/helper'
import {
  ListPriceWrapper,
  PriceItemWrapper,
} from '../../../Modal/components/SelectPriceSFCModal/index'
import { CUSTOM_COURSE_2 } from 'utils/constants'

export const minTwoDigits = (n) => {
  const number = parseInt(n)
  if (!number) {
    return ''
  }
  if (number < 100 && number >= 10) {
    return '0' + number
  }
  return (number < 10 ? '00' : '') + number
}

const Billing = ({
  id,
  course,
  history,
  dispatch,
  setAlert,
  isViaFrontiers,
  applicationFee,
  isAllowApplicationFee,
  firstMediaDomain,
  isUnicornDomain,
  selectedSchedule,
  userPaymentMethod,
  isArtofnumbersDomain,
  isFlcDomain,
  isPortalDomain,
  userPaymentByCourse,
  allQuantDomain,
  imadviserDomain,
  isClassCompareDomain,
  cyberQuoteDomain,
  userInfo,
  schedules,
  allowSpecialPrice,
  specialPrice,
  ref,
  requiredSchedule,
  handlePaymentMethodFreeCourse,
  sfcPrices,
  priceOptions,
  remainingAmount,
  complete,
  setComplete,
  ascendoDomain,
  therapadaDomain,
  isBellDomain,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const submitRef = useRef(null)
  const selectPaymentRef = useRef(null)
  const [error, setError] = useState('')
  const [discount, setDiscount] = useState(0)
  const [paymentRequest, setPaymentRequest] = useState(null)
  const refId = useSelector(makeSelectRefId())
  const domain = useSelector(makeSelectDomain())
  const currentUser = useSelector(makeSelectCurrentUser())
  const userLocation = useSelector(makeSelectUserLocation())
  const selectedPriceSFC = useSelector(makeSelectSelectedPriceSFC())
  const selectedModuleSFC = useSelector(makeSelectSelectedModuleSFC())
  const isCustomCourse = get(course, 'id') === CUSTOM_COURSE_2
  const isAdmin = get(currentUser, 'is_admin', false)
  const allowStripe = get(domain, 'allow_stripe_payment', false)
  const countryCode = `en_${get(userLocation, 'country', '')}`
  const isComplete = get(sfcPrices, 'length') === complete
  const enableSelectedPrice =
    Array.isArray(priceOptions) && priceOptions.length > 0

  useEffect(() => {
    if (requiredSchedule && !selectedSchedule) {
      showError('Please select schedule first!')
      return history.push(`/checkout/${id}/confirm`)
    }
  }, [requiredSchedule, selectedSchedule])

  useEffect(() => {
    // handle payment with google pay & apple pay
    if (!stripe || !elements || !course) {
      return
    }
    try {
      const pr = stripe.paymentRequest({
        country: 'SG',
        currency: therapadaDomain ? 'usd' : 'sgd',
        total: {
          label: get(course, 'name', ''),
          amount: getAmountCourse() * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      pr.canMakePayment().then((result) => {
        console.log('🚀 ~ result:', result)
        if (result) {
          setPaymentRequest(pr)
        }
      })

      pr.on('paymentmethod', async (e) => {
        const clientSecret = await getClientSecret()
        if (!clientSecret) {
          setAlert('failed')
          return history.push(`/checkout/${get(course, 'id')}/fail`)
        }

        const { error, paymentIntent } = stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          { handleActions: false }
        )

        if (error) {
          e.complete('fail')
          setAlert('failed')
          return history.push(`/checkout/${get(course, 'id')}/fail`)
        }

        e.complete('success')
        if (paymentIntent?.status === 'requires_action') {
          stripe.confirmCardPayment(clientSecret)
        }
        const res = await handlePaymentWithPaynow_Credit(
          {
            payment_method:
              e.methodName === 'google-pay' ? 'google_pay' : 'apple_pay',
            payment_intent: get(paymentIntent, 'id', ''),
            value: getAmountCourse(),
          },
          true
        )

        if (res) {
          dispatch(postEnroll({ schedule: selectedSchedule, is_send: true }))
        }
      })
    } catch (err) {
      console.log('err', err)
    }
  }, [stripe, elements, course])

  useEffect(() => {
    if (userInfo) {
      formik.setFieldValue('credit', get(userInfo, 'passport'))
    }
  }, [userInfo])

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserPaymentMethod({ courseId: id }))
      dispatch(getUserInfo())
    }
  }, [currentUser])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    }, 1000)
  }, [])
  const initialPaymentMethod = () => {
    if (isUnicornDomain || isFlcDomain || isPortalDomain || firstMediaDomain) {
      if (!!remainingAmount) {
        return 'paynow'
      }
      if (isCustomCourse) {
        return 'paynow'
      }
      return 'credit'
    }

    if (ascendoDomain) {
      return 'stripe'
    }

    if (isArtofnumbersDomain) {
      return 'paynow'
    }

    if (allQuantDomain || cyberQuoteDomain || isViaFrontiers) {
      return 'paypal'
    }

    return ''
  }

  const formik = useFormik({
    initialValues: {
      fullName: '',
      payment_method: initialPaymentMethod(),
      paynow: '',
      credit: '',
      attachment: '',
      attachment_name: '',
      attachment_credit: '',
      attachment_name_credit: '',
      voucher: '',
      orderId: '',
      paypal_success: false,
      company_name: '',
      company_billing_address: '',
      contact_email: '',
      contact_mobile: '',
      contact_name: '',
    },
    onSubmit: async (values) => {
      dispatch(showGlobalLoading())
      const invoiceSequence = await getInvoiceNumber({ domain: 'flc' })

      if (handleCheckPaymentMethod(values.payment_method, values)) {
        dispatch(hideGlobalLoading())
        return
      } else {
        switch (values.payment_method) {
          case 'paynow':
            handlePaymentWithPaynow_Credit(values, false, null, invoiceSequence)
            break
          case 'credit':
            handlePaymentWithSFC(values, invoiceSequence)
            break
          case 'stripe':
            handlePaymentWithStripe(stripe, elements, values)
            break
          case 'paypal':
            handlePaymentWithPaynow_Credit(values, false, null, invoiceSequence)
            break
          case 'company_sponsored':
            handlePaymentWithPaynow_Credit(values, false, null, invoiceSequence)
            break
          case 'wechat_pay':
            handlePaymentWithWeChatPay()
            break
          default:
            dispatch(hideGlobalLoading())
            showError('You need to select one payment method to enroll!')
            break
        }
      }
    },
  })

  const handleCheckPaymentMethod = (paymentMethod, values) => {
    let error = false

    if (enableSelectedPrice && !selectedPriceSFC) {
      showError('You need to select at least one price to pay!')
      return true
    }

    switch (paymentMethod) {
      case 'paynow':
        if (!values.paynow && !firstMediaDomain) {
          formik.setFieldError('paynow', ERROR_MESSAGE)
          return true
        }
        if (!values.attachment) {
          showError('You need to upload your bank transfer screenshot!')
          return true
        }
        break
      case 'credit':
        if (!values.credit) {
          formik.setFieldError('credit', ERROR_MESSAGE)
          return true
        }
        if (values.credit.length !== 9 || !validateNric(values.credit)) {
          dispatch(hideSelectPriceSFCModal())
          formik.setFieldError(
            'credit',
            'Please input correct nric format (eg. S9576291C)'
          )
          return true
        }
        break
      case 'stripe':
        if (!values.fullName) {
          formik.setFieldError('fullName', ERROR_MESSAGE)
          return true
        }

        break
      case 'paypal':
        if (!values.paypal_success) {
          showError('You need to pay via paypal first!')
          return true
        }
        break

      case 'company_sponsored':
        const errors = []
        if (!values.company_name) {
          errors.push('company_name')
        }
        if (!values.company_billing_address) {
          errors.push('company_billing_address')
        }

        if (!values.contact_email) {
          errors.push('contact_email')
        }

        if (!values.contact_name) {
          errors.push('contact_name')
        }
        if (!values.contact_mobile) {
          errors.push('contact_mobile')
        }
        if (errors.length !== 0) {
          errors.forEach((fieldName) =>
            formik.setFieldError(fieldName, ERROR_MESSAGE)
          )
          return true
        }

        if (!validateEmail(values.contact_email)) {
          formik.setFieldError('contact_email', 'Email must be a valid email')
          return true
        }
        break
      default:
        break
    }

    return error
  }

  const format = (amount) => {
    const surplus = (amount * 100) % 100
    if (surplus === 0) {
      return amount + '.00'
    }
    return amount + ''
  }

  const handlePaymentWithSFC = async (values, invoiceSequence) => {
    if (!selectedPriceSFC) {
      dispatch(hideGlobalLoading())
      return showError('You need to select at least one price to pay!')
    }
    let invoice = ''
    if (firstMediaDomain) {
      invoice = await getInvoiceFirstMedia()
    } else if (isUnicornDomain) {
      invoice = await getInvoicePdf()
    }

    const params = {
      claimRequest: {
        course: {
          id: get(selectedPriceSFC, 'course_code', ''),
          fee: format(get(selectedPriceSFC, 'price')),
          runId: '',
          startDate: get(selectedSchedule, 'start_date', ''),
        },
        individual: {
          nric: values.credit,
          email:
            get(currentUser, 'username', '') || get(currentUser, 'login', ''),
          homeNumber: '',
          mobileNumber: '',
        },
        additionalInformation: '',
      },
    }
    const res = await handlePaymentWithPaynow_Credit(
      values,
      true,
      invoice,
      invoiceSequence
    )
    if (res) {
      dispatch(checkoutWithSFCPayment(params))
    }
  }

  const handlePaymentWithPaynow_Credit = async (
    values,
    isSFCPayment,
    invoice,
    invoiceSequence
  ) => {
    let invoiceFlc = ''
    if (isFlcDomain || isPortalDomain) {
      invoiceFlc = await getInvoiceFLCDomain(invoiceSequence)
    } else if (isUnicornDomain && !invoice) {
      invoiceFlc = await getInvoicePdf()
    }

    const userId = get(currentUser, 'uid', '') || get(currentUser, 'id', '')
    const body = {
      user_id: userId,
      channel_id: get(course, 'id', ''),
      payment_method: values.payment_method,
      domain: get(domain, 'domain', ''),
      schedule_id: get(selectedSchedule, 'id', 0),
    }

    const invoiceNo = `${minTwoDigits(invoiceSequence || '')}-${dayjs().format(
      'MMDD'
    )}-${get(selectedSchedule, 'class_code', '')}`

    if (isFlcDomain || isPortalDomain) {
      if (!invoiceFlc) {
        dispatch(hideGlobalLoading())
        return showError(
          'Error with the invoice, pls refresh & proceed to enrol again!'
        )
      }
      body.additional_info = JSON.stringify({
        invoice_no: invoiceNo,
        sequence_num: minTwoDigits(invoiceSequence || ''),
        class_code: get(selectedSchedule, 'class_code', ''),
        course_fee: get(selectedPriceSFC, 'price', 0),
      })
    }

    if (values.payment_method === 'paypal') {
      body.value = values.orderId
    }

    if (values.payment_method === 'paynow') {
      body.attachment =
        typeof values.attachment === 'string'
          ? values.attachment.split('base64,')[1]
          : ''
      body.attachment_name = values.attachment_name
      body.value = values.paynow
      if (isFlcDomain) {
        body.invoice_url = get(invoiceFlc, 'pdf', '')
      }
      if (isUnicornDomain) {
        body.invoice_url = get(invoiceFlc, 'pdf', '')
        body.additional_info = JSON.stringify({
          course_fee: get(selectedPriceSFC, 'price', 0),
        })
      }
    }

    if (
      values.payment_method === 'apple_pay' ||
      values.payment_method === 'google_pay'
    ) {
      body.value = values.payment_intent || ''
      body.additional_info = JSON.stringify({
        course_fee: values.value,
      })
    }

    if (values.payment_method === 'credit') {
      body.course_code = get(selectedPriceSFC, 'course_code', '')
      body.is_finished = get(selectedPriceSFC, 'is_finished', false)
      body.course_fee = parseFloat(get(selectedPriceSFC, 'price', false))
      body.attachment = get(invoice, 'pdf', '')
      body.attachment_size = get(invoice, 'fileSize', '')
      body.nric = values.credit
      body.schedule_id = get(selectedSchedule, 'id', '')

      if (isFlcDomain || isPortalDomain) {
        if (!invoiceFlc) {
          dispatch(hideGlobalLoading())
          return showError(
            'Error with the invoice, pls refresh & proceed to enrol again!'
          )
        }
        body.attachment = get(invoiceFlc, 'pdf', '')
        body.attachment_size = get(invoiceFlc, 'fileSize', '')
      }
      if (isUnicornDomain) {
        body.additional_info = JSON.stringify({
          course_fee: get(selectedPriceSFC, 'price', 0),
        })
      }
    }

    if (values.payment_method === 'company_sponsored') {
      body.additional_info = JSON.stringify({
        invoice_no: invoiceNo,
        sequence_num: minTwoDigits(invoiceSequence || ''),
        class_code: get(selectedSchedule, 'class_code', ''),
        company_name: values.company_name,
        company_billing_address: values.company_billing_address,
        contact_name: values.contact_name,
        contact_email: values.contact_email,
        contact_mobile: values.contact_mobile,
        course_fee: get(selectedPriceSFC, 'price', 0),
      })
      if (isFlcDomain || isPortalDomain) {
        body.invoice_url = get(invoiceFlc, 'pdf', '')
      }
    }

    if (refId) {
      body.ref_id = parseFloat(refId)
    }

    try {
      const res = await fetch(`${config.baseUrl}/api/payments/payment-method`, {
        method: 'PUT',
        headers: {
          'Content-type': 'Application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      })

      if (res.status !== 200) {
        return history.push(`/checkout/${get(course, 'id')}/fail`)
      }
      const partners = get(course, 'partners', [])
      const isEnroll = isEnrollCourse(partners, course)
      const data = await res.json()
      const id = get(data, 'data.id', null)

      if (isSFCPayment) {
        return id ? true : false
      }

      if (id && !isEnroll) {
        dispatch(postEnroll({ schedule: selectedSchedule, is_send: true }))
      } else {
        history.push(`/checkout/${get(course, 'id')}/complete`)
      }
      dispatch(getUserPaymentMethod({ courseId: id }))
      dispatch(loadCourse(get(course, 'id')))
      dispatch(hideGlobalLoading())
    } catch (err) {
      dispatch(hideGlobalLoading())
      return false
    }
  }

  const getAmountCourse = () => {
    let amount = 0
    if (isAllowApplicationFee) {
      amount = applicationFee
    } else if (allowSpecialPrice) {
      amount = specialPrice
    } else {
      amount = get(course, 'price')
    }
    return amount
  }

  const getClientSecret = async (type = 'card') => {
    let amount = getAmountCourse()

    const requestURL = `${config.baseUrl}/api/payments`

    try {
      const res = await fetch(requestURL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          amount,
          description: get(course, 'name', ''),
          type,
        }),
      })

      const data = await res.json()

      if (data.data.secret) {
        return data.data.secret
      } else {
        return false
      }
    } catch (err) {
      console.log('err', err)
      return false
    }
  }

  const handlePaymentWithStripe = async (stripe, elements, values) => {
    const res = await handlePaymentWithPaynow_Credit(values, true)
    if (res) {
      const secret = await getClientSecret()

      if (!secret) {
        setAlert('failed')
        return history.push(`/checkout/${get(course, 'id')}/fail`)
      }

      if (!stripe || !elements) {
        return
      }

      const cardElement = elements.getElement(CardNumberElement)
      const result = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: values.fullName,
            email:
              get(currentUser, 'username', '') || get(currentUser, 'login', ''),
          },
        },
      })

      if (result.error) {
        setAlert('failed')
        setError(result.error.message)
        history.push(`/checkout/${get(course, 'id')}/fail`)
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          dispatch(postEnroll({ schedule: selectedSchedule, is_send: true }))
        }
      }
      setTimeout(() => {
        setAlert('')
      }, 3000)
      dispatch(hideGlobalLoading())
    }
  }

  const handlePaymentWithWeChatPay = async () => {
    const secret = await getClientSecret('wechat_pay')

    if (!secret) {
      setAlert('failed')
      return history.push(`/checkout/${get(course, 'id')}/fail`)
    }

    if (!stripe) {
      return
    }

    const result = await stripe.confirmWechatPayPayment(secret, {
      payment_method_options: {
        wechat_pay: {
          client: 'web',
        },
      },
    })

    if (result.error) {
      setAlert('failed')
      setError(result.error.message)
      history.push(`/checkout/${get(course, 'id')}/fail`)
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        dispatch(postEnroll({ schedule: selectedSchedule, is_send: true }))
      }
    }
    setTimeout(() => {
      setAlert('')
    }, 3000)
    dispatch(hideGlobalLoading())
  }

  const handleNextStep = async () => {
    const price = get(course, 'price', 0)
    const specialPrice = get(course, 'special_price', 0)

    let applyDiscount = price - (price * discount) / 100

    if (allowSpecialPrice) {
      applyDiscount = specialPrice - (specialPrice * discount) / 100
    }

    if (enableSelectedPrice && !selectedPriceSFC) {
      showError('You need to select at least one price to pay!')
      return true
    }

    if (price === 0 || applyDiscount === 0) {
      dispatch(showGlobalLoading())
      const setPayment = await handlePaymentMethodFreeCourse()
      if (setPayment) {
        setTimeout(() => {
          dispatch(
            postEnroll({ schedule: selectedSchedule, ref, is_send: true })
          )
          dispatch(hideGlobalLoading())
        }, 1500)
      }
      return
    }
    formik.submitForm()
  }

  const getInvoicePdf = async () => {
    const invoiceNo = `${dayjs().format('YYYYMMDD')}-${get(
      course,
      'sfc_prices[0].course_code'
    )}-${
      isAdmin ? get(currentUser, 'partner.id', '') : get(currentUser, 'uid', '')
    }`

    const studentName =
      get(userInfo, 'name', '') ||
      get(currentUser, 'partner_display_name', '') ||
      get(currentUser, 'partner.name', '')

    try {
      const res = await fetch(`${config.api}/pdf`, {
        body: JSON.stringify({
          trainee_name: studentName,
          invoice_no: invoiceNo,
          student_name: studentName,
          course_start_date: dayjs(get(selectedSchedule, 'start_date')).format(
            'YYYY-MM-DD'
          ),
          course_end_date: dayjs(get(selectedSchedule, 'end_date')).format(
            'YYYY-MM-DD'
          ),
          course_fee: get(selectedPriceSFC, 'price', 0),
          course: course,
        }),
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      })
      const data = await res.json()
      if (data.pdf) {
        return data
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const getInvoiceNumber = async (values) => {
    const requestURL = `${config.baseUrl}/api/invoices/numbers`
    try {
      const res = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          domain: values.domain,
          channel_id: parseInt(id),
        }),
      })
      const data = await res.json()
      if (data?.data?.value) {
        return data?.data?.value
      }
    } catch (err) {
      return null
    }
  }

  const getInvoiceFLCDomain = async (invoiceSequence) => {
    const invoiceNo = `${minTwoDigits(invoiceSequence || '')}-${dayjs().format(
      'MMDD'
    )}-${get(selectedSchedule, 'class_code', '')}`

    const studentName =
      get(userInfo, 'name', '') ||
      get(currentUser, 'partner_display_name', '') ||
      get(currentUser, 'partner.name', '')

    const companySponsored = get(
      parseSafe(userInfo.additional_info),
      'company',
      ''
    )
    const billingAddress = get(
      parseSafe(userInfo.additional_info),
      'user_billing_address',
      ''
    )
    try {
      const res = await fetch(`${config.api}/send-mail/invoice-flc`, {
        body: JSON.stringify({
          trainee_name: studentName,
          invoice_no: invoiceNo,
          student_name: studentName,
          course_start_date: get(selectedSchedule, 'start_date', ''),
          course_end_date: get(selectedSchedule, 'end_date', ''),
          course_fee: parseFloat(get(selectedPriceSFC, 'price', 0)),
          course: course,
          company_sponsored: formik.values.company_name || companySponsored,
          company_billing_address: formik.values.company_billing_address,
          billing_address: billingAddress,
        }),
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      })
      const data = await res.json()
      if (data.pdf) {
        return data
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleNumberInput = (currentTarget, field) => {
    let regExp = /^[0-9]{1,15}$/

    if (regExp.test(currentTarget.value) || currentTarget.value === '') {
      formik.setFieldValue(field, currentTarget.value)
    }
  }

  const getInvoiceFirstMedia = async () => {
    const courseFee = getOptionPrice(selectedModuleSFC, 'highest')
    const netFee = getOptionPrice(selectedModuleSFC)
    const gst = courseFee - courseFee / 1.07
    const trainingGrant = courseFee - netFee

    try {
      const res = await fetch(`${config.api}/invoice-firstmedia`, {
        body: JSON.stringify({
          selectedPriceSFC,
          courseFee,
          netFee,
          gst,
          trainingGrant,
          selectedSchedule,
          profile: userInfo,
        }),
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      })
      const data = await res.json()
      if (data.pdf) {
        return data
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const renderIndex = () => {
    if (!requiredSchedule) {
      return 2
    }
    return 3
  }

  const getClientId = () => {
    if (isViaFrontiers) {
      return paypalKey.viaFrontiers
    }
    if (isClassCompareDomain) {
      return paypalKey.classCompare
    }
    if (allQuantDomain) {
      return paypalKey.allQuant
    }
    return paypalKey.default
  }

  const handleSelectPriceSFC = (price) => {
    if (price) {
      const courseCode = get(sfcPrices, '[0].course_code')
      const title = get(price, 'title', '')
      const optionPrice = get(price, 'price')

      dispatch(
        setSelectedPriceSFC({
          course_code: courseCode,
          title,
          price: optionPrice,
          is_finished: isComplete,
        })
      )
    }

    window.scrollTo({
      behavior: 'smooth',
      top: selectPaymentRef.current.offsetTop + 100,
    })
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <CheckoutProgressBar
        indexActive={renderIndex()}
        isViaFrontiers={isViaFrontiers}
        isUnicornDomain={isUnicornDomain}
        cyberQuoteDomain={cyberQuoteDomain}
        firstMediaDomain={firstMediaDomain}
        requiredSchedule={requiredSchedule}
        isArtofnumbersDomain={isArtofnumbersDomain}
      />

      <div className="hs-confirm">
        {enableSelectedPrice ? (
          <>
            <div
              className="hs-confirm__head"
              style={{ borderBottom: 0, marginBottom: 0 }}
            >
              <h3 className="hs-confirm__heading">Select Pricing Tier</h3>
            </div>
            <ListPriceWrapper
              style={{ justifyContent: 'flex-start', marginBottom: 15 }}
            >
              {priceOptions.map((price) => {
                const selectedPrice = selectedPriceSFC?.price === price.price
                return (
                  <PriceItemWrapper key={price.title}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        height: '100%',
                      }}
                    >
                      <div>
                        <div className="price__title">{price.title}</div>
                        <div className="price">{formatMoney(price.price)}</div>
                      </div>
                      <div style={{ marginTop: 'auto' }}>
                        <BtnPrimary
                          selectedPrice={selectedPrice}
                          name={selectedPrice ? 'Selected' : 'Select'}
                          style={{
                            fontWeight: 'bold',
                            padding: '12px 10px',
                            color: '#fff',
                            width: '100%',
                            backgroundColor: selectedPrice
                              ? 'rgb(255, 13, 107)'
                              : '',
                          }}
                          type="button"
                          handleClick={() => handleSelectPriceSFC(price)}
                        />
                      </div>
                    </div>
                  </PriceItemWrapper>
                )
              })}
            </ListPriceWrapper>
          </>
        ) : null}

        {!!remainingAmount && (
          <p className="partial-claim__text">
            You have made a partial Skillsfuture credit claim, please pay the
            remaining amount with another payment method below or get in touch
            with the course provider.
          </p>
        )}

        <div className="hs-confirm__head">
          <h3 className="hs-confirm__heading" ref={selectPaymentRef}>
            Select your payment method
          </h3>
        </div>
        <Row
          className="hs-billing"
          style={{ marginBottom: 35 }}
          justify="space-between"
        >
          <Col xs={22} md={12} style={{ margin: '0 20px' }}>
            <PayPalScriptProvider
              options={{
                'client-id': getClientId(),
                currency: 'SGD',
                locale: countryCode,
              }}
            >
              <CardInformation
                submitRef={submitRef}
                error={error}
                formik={formik}
                course={course}
                history={history}
                dispatch={dispatch}
                currentUser={currentUser}
                applicationFee={applicationFee}
                userPaymentByCourse={userPaymentByCourse}
                isAllowApplicationFee={isAllowApplicationFee}
                isViaFrontiers={isViaFrontiers}
                firstMediaDomain={firstMediaDomain}
                isUnicornDomain={isUnicornDomain}
                selectedSchedule={selectedSchedule}
                isArtofnumbersDomain={isArtofnumbersDomain}
                allQuantDomain={allQuantDomain}
                imadviserDomain={imadviserDomain}
                cyberQuoteDomain={cyberQuoteDomain}
                allowSpecialPrice={allowSpecialPrice}
                specialPrice={specialPrice}
                isFlcDomain={isFlcDomain}
                isPortalDomain={isPortalDomain}
                handleNumberInput={handleNumberInput}
                complete={complete}
                setComplete={setComplete}
                paymentRequest={paymentRequest}
                allowStripe={allowStripe}
                selectedPriceSFC={selectedPriceSFC}
                remainingAmount={remainingAmount}
                ascendoDomain={ascendoDomain}
                isCustomCourse={isCustomCourse}
                isBellDomain={isBellDomain}
              />
            </PayPalScriptProvider>
          </Col>
          <Col xs={24} md={10}>
            <CartSummary
              remainingAmount={remainingAmount}
              course={course}
              formik={formik}
              discount={discount}
              allowSpecialPrice={allowSpecialPrice}
              specialPrice={specialPrice}
              setDiscount={setDiscount}
              applicationFee={applicationFee}
              isAllowApplicationFee={isAllowApplicationFee}
              selectedSchedule={selectedSchedule}
              selectedPriceSFC={selectedPriceSFC}
              enableSelectedPrice={enableSelectedPrice}
              ascendoDomain={ascendoDomain}
              therapadaDomain={therapadaDomain}
            />
          </Col>
        </Row>
      </div>
      <ProgressBtn
        submitRef={submitRef}
        billing={true}
        course={course}
        formik={formik}
        discount={discount}
        dispatch={dispatch}
        handleNextStep={handleNextStep}
        applicationFee={applicationFee}
        isAllowApplicationFee={isAllowApplicationFee}
        isUnicornDomain={isUnicornDomain}
        isFlcDomain={isFlcDomain}
        selectedSchedule={selectedSchedule}
        paymentMethod={formik.values.payment_method}
        firstMediaDomain={firstMediaDomain}
        specialPrice={specialPrice}
        allowSpecialPrice={allowSpecialPrice}
        cyberQuoteDomain={cyberQuoteDomain}
        selectedPriceSFC={selectedPriceSFC}
        therapadaDomain={therapadaDomain}
      />
    </form>
  )
}

export default Billing
